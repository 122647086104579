import {call, put, all, takeEvery} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
     successDownloadMediaFile,
     failDownloadMediaFile,
} from '../reducers/customReducer';
import * as ACTIONS from '../actions';
import * as Service from '../../services';
import { handleErrors } from '../../utils/sagaUtils';

export function* downloadMediaFileSaga({ payload }) {
     const response = yield call(Service.downloadMediaFile, payload);

     if (response.status === 200) {
          return yield put(successDownloadMediaFile());
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     response.errors && handleErrors(response.errors);
     yield put(failDownloadMediaFile(response.message));
}

export function* watchCustom() {
     yield all([
          takeEvery(ACTIONS.DOWNLOAD_MEDIA_FILE_REQUEST, downloadMediaFileSaga)
     ])
}
