import React, {useState, useEffect, useRef} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Row, Col, Form, Dropdown } from 'react-bootstrap';
import ChevronDownIcon from '../../../../assets/icons/metronic/chevron-down-icon';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import Input from '../../../common/input';
import { ORGANIZATION } from '../../../../constants/form.constants';
import {errorsRefresh} from "../../../../redux/reducers/errorsReducer";
import { DefaultSpinner } from '../../../common/spinner/DefaultSpinner';
import CustomToggle from '../../../common/custom-toggle';

const mapStateToProps = (state, ownProps) => ({
     ...ownProps,
     router: state.router,
     newOrganizationRequested: state.organizationReducer.newOrganizationRequested,
     updateOrganizationRequested: state.organizationReducer.updateOrganizationRequested,
     errors: state.errorsReducer.errors
});

const mapDispatchToProps = (dispatch) => ({
     refreshErrors: () => dispatch(errorsRefresh())
});

const OrganizationForm = ({ btnText, ...props }) => {
     const {
          organization,
          languages,
          handleSave,
          newOrganizationRequested,
          updateOrganizationRequested,
          errors,
          refreshErrors,
          tiers
     } = props;
     
     const { t } = useTranslation();
     const form = useRef();
     const handleSubmit = (values) => handleSave({ ...values });

     const [innerState, setInnerState] = useState({
          statusOptions: ['active', 'suspended'],
          currentStatus: 'active',
          languageOptions: [],
          currentLanguage: {
               id: null,
               name: t('organizations.language_placeholder'),
               code: null
          },
          tierOptions: [],
          currentTier: {
               id: null,
               name: null,
               price: null
          }
     });

     useEffect(() => {
          if (errors && Object.keys(errors).length > 0) {
               form.current.setSubmitting(false);
               form.current.setErrors(errors);

               refreshErrors();
          }
     }, [errors]);

     const currentLanguage = () => {
          if (organization) {
           
               return languages.find(lang => lang.id === organization.language.id);
          }

          return {
               id: null,
               name: t('organizations.language_placeholder'),
               code: null
          };
     };

     const currentTier = () => {
          if (organization) {
           
               return tiers.find(tier => tier.id === organization.tier.id);
          }

          return {
               id: null,
               name: t('organizations.tier_placeholder'),
               price: null
          };
     };

     const currentStatus = () => {
          if (organization) {

               return organization.status;
          }

          return 'active';
     };

     useEffect(() => {
          if (tiers && languages) {
               setInnerState({
                    ...innerState,
                    currentStatus: currentStatus(),
                    tierOptions: tiers,
                    currentTier: currentTier(),
                    languageOptions: languages,
                    currentLanguage: currentLanguage()
               });
          }
     }, [organization, languages, tiers]);

     return (
          languages.length > 0 && tiers.length > 0 && (
               <Formik
                    validationSchema={ORGANIZATION.VALIDATION}
                    enableReinitialize
                    onSubmit={handleSubmit}
                    initialValues={{
                         name: organization && organization.name,
                         language_id: organization && organization.language_id,
                         tier_id: organization && organization.tier_id,
                         status: organization ? organization.status : 'active'
                    }}
                    validateOnBlur={false}
                    innerRef={form}
               >
                    {({
                         handleSubmit,
                         handleChange,
                         setFieldValue,
                         values,
                         touched,
                         errors
                    }) => (
                         <Form noValidate onSubmit={handleSubmit}>
                              <Form.Group as={Row} controlId="name">
                                   <Col sm={3}>
                                        <Form.Label>
                                             {t('organizations.name')}
                                        </Form.Label>
                                   </Col>
                                   <Col sm={9}>
                                        <Input
                                             type="text"
                                             field="name"
                                             values={values}
                                             placeholder={t('organizations.name_placeholder')}
                                             handleChange={handleChange}
                                             touched={touched}
                                             errors={errors}
                                        />
                                   </Col>
                              </Form.Group>

                              <Form.Group
                                   as={Row}
                                   controlId="language"
                                   className="language"
                              >
                                   <Col sm={3}>
                                        <Form.Label>
                                             {t(
                                                  'organizations.default_language'
                                             )}
                                        </Form.Label>
                                   </Col>
                                   <Col sm={9}>
                                        <div className="input-group zi10">
                                             <Dropdown
                                                  style={{ width: '100%' }}
                                                  className={errors['language_id'] ? 'is-invalid' : ''}
                                             >
                                                  <Dropdown.Toggle
                                                       className={'select-dropdown-toggle form-control'}
                                                       as={CustomToggle}
                                                  >
                                                       {
                                                            innerState.currentLanguage.name
                                                       }
                                                  </Dropdown.Toggle>
                                                  <Dropdown.Menu
                                                       style={{ width: '100%' }}
                                                  >
                                                       {innerState.languageOptions.map(
                                                            (lang) => (
                                                                 <Dropdown.Item
                                                                      key={
                                                                           lang.id
                                                                      }
                                                                      onClick={() => {
                                                                           setInnerState(
                                                                                {
                                                                                     ...innerState,
                                                                                     currentLanguage: lang,
                                                                                }
                                                                           )
                                                                           setFieldValue('language_id', lang.id);
                                                                      }}
                                                                 >
                                                                      {lang.name}
                                                                 </Dropdown.Item>
                                                            )
                                                       )}
                                                  </Dropdown.Menu>
                                             </Dropdown>
                                             <Form.Control.Feedback
                                                  type="invalid"
                                                  style={{ marginTop: '2px', display: 'block' }}
                                             >
                                                  {touched['language_id'] && errors['language_id']}
                                             </Form.Control.Feedback> 
                                             <span className="suffix">
                                                  <ChevronDownIcon />
                                             </span>
                                        </div>
                                   </Col>
                              </Form.Group>

                              <Form.Group
                                   as={Row}
                                   controlId="language"
                                   className="language"
                              >
                                   <Col sm={3}>
                                        <Form.Label>
                                             {t(
                                                  'organizations.status'
                                             )}
                                        </Form.Label>
                                   </Col>
                                   <Col sm={9}>
                                        <div className="input-group zi9">
                                             <Dropdown
                                                  style={{ width: '100%' }}
                                                  className={errors['language_id'] ? 'is-invalid' : ''}
                                             >
                                                  <Dropdown.Toggle
                                                       className={'select-dropdown-toggle form-control'}
                                                       as={CustomToggle}
                                                  >
                                                       {t(`organizations.status_${innerState.currentStatus}`)}
                                                  </Dropdown.Toggle>
                                                  <Dropdown.Menu style={{ width: '100%' }} >
                                                       {innerState.statusOptions.map(
                                                            status => (
                                                                 <Dropdown.Item
                                                                      key={status}
                                                                      onClick={() => {
                                                                           setInnerState(
                                                                                {
                                                                                     ...innerState,
                                                                                     currentStatus: status,
                                                                                }
                                                                           )
                                                                           setFieldValue('status', status);
                                                                      }}
                                                                 >
                                                                      {t(`organizations.status_${status}`)}
                                                                 </Dropdown.Item>
                                                            )
                                                       )}
                                                  </Dropdown.Menu>
                                             </Dropdown>
                                             <Form.Control.Feedback
                                                  type="invalid"
                                                  style={{ marginTop: '2px', display: 'block' }}
                                             >
                                                  {touched['status'] && errors['status']}
                                             </Form.Control.Feedback> 
                                             <span className="suffix">
                                                  <ChevronDownIcon />
                                             </span>
                                        </div>
                                   </Col>
                              </Form.Group>

                              <Form.Group
                                   as={Row}
                                   controlId="tier_id"
                                   className="tier_id"
                              >
                                   <Col sm={3}>
                                        <Form.Label>
                                             {t(
                                                  'organizations.tier'
                                             )}
                                        </Form.Label>
                                   </Col>
                                   <Col sm={9}>
                                        <div className="input-group zi8">
                                             <Dropdown
                                                  style={{ width: '100%' }}
                                                  className={errors['tier_id'] ? 'is-invalid' : ''}
                                             >
                                                  <Dropdown.Toggle
                                                       className={'select-dropdown-toggle form-control'}
                                                       as={CustomToggle}
                                                  >
                                                       {innerState.currentTier.name} {innerState.currentTier.price && innerState.currentTier.price}
                                                  </Dropdown.Toggle>
                                                  <Dropdown.Menu style={{ width: '100%' }}>
                                                       {innerState.tierOptions.map(
                                                            tier => (
                                                                 <Dropdown.Item
                                                                      key={`tier-${tier.id}`}
                                                                      onClick={() => {
                                                                           setInnerState(
                                                                                {
                                                                                     ...innerState,
                                                                                     currentTier: tier,
                                                                                }
                                                                           )
                                                                           setFieldValue('tier_id', tier.id);
                                                                      }}
                                                                 >
                                                                      {tier.name} ({tier.price})
                                                                 </Dropdown.Item>
                                                            )
                                                       )}
                                                  </Dropdown.Menu>
                                             </Dropdown>
                                             <Form.Control.Feedback
                                                  type="invalid"
                                                  style={{ marginTop: '2px', display: 'block' }}
                                             >
                                                  {touched['tier_id'] && errors['tier_id']}
                                             </Form.Control.Feedback> 
                                             <span className="suffix">
                                                  <ChevronDownIcon />
                                             </span>
                                        </div>
                                   </Col>
                              </Form.Group>

                              <hr className="body-divider" />

                              <div className="form-actions">
                                   <Link to={`/organizations`}>
                                        <Button variant="default">
                                             {t('buttons.back')}
                                        </Button>
                                   </Link>
                                   <Button
                                        variant="success"
                                        size="sm"
                                        type="submit"
                                        disabled={newOrganizationRequested || updateOrganizationRequested}
                                   >
                                        {btnText}
                                        {<DefaultSpinner isLoading={newOrganizationRequested || updateOrganizationRequested} />}
                                   </Button>
                              </div>
                         </Form>
                    )}
               </Formik>
          )
     );
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationForm);
