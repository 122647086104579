import styled from 'styled-components';
import {
     FONT,
     COLORS,
     FLEX_ROW_CENTER,
     SIZE,
} from '../../../constants/style.constants';

const AuthPageWrapper = styled.div`
     position: relative;
     display: flex;
     flex-direction: column;
     align-self: center;
     justify-content: center;
     min-width: 100%;

     .lang-button {
          position: fixed;
          top: 45px;
          right: 50px;
     }

     .transition-group-wrapper {
          display: flex;
          overflow: hidden;
          align-items: center;
          justify-content: space-around;
          width: 100%;

          &.center {
               align-items: center;
          }
     }

     .from-hidden {
          margin-left: -100%;
          transition: all 0.8s ease-in-out;
     }

     .from-visible {
          transition: all 0.8s ease-in-out;
     }

     .to-hidden {
          margin-right: -100%;
          transition: all 0.8s ease-in-out;
          min-width: 450px;

          @media only screen and (max-width: 620px) and (min-width: 520px) {
               min-width: 400px;
          }

          @media only screen and (max-width: 519px) and (min-width: 420px) {
               min-width: 350px;
          }

          @media only screen and (max-width: 419px) and (min-width: 320px) {
               min-width: 300px;
          }
     }

     .to-visible {
          transition: all 0.8s ease-in-out;
     }

     @media screen and (max-width: 1366px) {
          align-self: start;
          display: flex;
          flex-direction: column;
          margin-top: 60px;
          .lang-button {
               display: none;
          }
     }

     @media only screen and (max-width: 620px) and (min-width: 520px) {
          min-width: 100%;
          margin-top: 60px;
          text-align: start;

          div {
               width: 400px;
          }
     }

     @media only screen and (max-width: 519px) and (min-width: 420px) {
          div {
               width: 350px;
          }
     }

     @media only screen and (max-width: 419px) and (min-width: 320px) {
          div {
               width: 300px;
          }
     }
`;

export const FormWrapper = styled.div`
     max-width: 450px;

     h1 {
          font-weight: ${FONT.MEDIUM};
          font-size: ${SIZE.GIGANTIC};
          /* line-height: 36px; */
          color: ${COLORS.DARK_BLUE};
          text-align: start;
          padding-bottom: 30px;
          margin: 0 !important;

          @media only screen and (max-width: 620px) {
               font-size: ${SIZE.ENORMOUS};
          }
     }

     .form-group {
          input {
               width: 450px;
               min-height: 50px;
               padding: 16px;
               font-family: 'IBM Plex Sans', sans-serif;
               font-size: ${SIZE.NORMAL};

               &::placeholder {
                    color: ${COLORS.PLACEHOLDER_GREY};
               }

               @media only screen and (max-width: 620px) and (min-width: 520px) {
                    width: 400px;
               }

               @media only screen and (max-width: 519px) and (min-width: 420px) {
                    width: 350px;
               }

               @media only screen and (max-width: 419px) and (min-width: 320px) {
                    width: 300px;
               }
          }
     }

     .bottom-form-links,
     .form-actions {
          ${FLEX_ROW_CENTER};
          margin-top: 30px;
     }

     .btn-push-right {
          display: flex;
          flex-direction: row-reverse;
          margin-top: 30px;
     }

     .btn {
          min-width: 123px;
          min-height: 50px;
     }

     a {
          font-weight: ${FONT.REGULAR};
          font-size: ${SIZE.NORMAL};
          /* line-height: 18px; */
          text-decoration: none;
          color: ${COLORS.PLACEHOLDER_GREY};
     }

     .divider {
          box-sizing: border-box;
          margin: 30px 0;
     }
`;

export const InfoWrapper = styled.div`
     display: flex;
     align-items: center;
     justify-content: center;
     flex-direction: column;
     max-width: 450px;

     h1 {
          font-weight: ${FONT.MEDIUM};
          font-size: ${SIZE.ENORMOUS};
          /* line-height: 31px; */
          text-align: center;
          color: ${COLORS.DARK_BLUE};
          padding-top: 20px;
          margin-bottom: 10px;
     }

     .form-actions {
          ${FLEX_ROW_CENTER};
          margin-top: 30px;
     }

     span {
          font-weight: ${FONT.MEDIUM};
          font-size: ${SIZE.NORMAL};
          /* line-height: 19px; */
          text-align: center;
          padding-bottom: 20px;
          color: ${COLORS.TEXT_GREY};
     }

     p {
          text-align: center;
          font-size: ${SIZE.NORMAL};
          margin: 0;
     }

     b {
          font-weight: ${FONT.MEDIUM};
     }

     .link-block {
          border-top: 1px solid ${COLORS.LIGHTER_BLUE};
          padding-top: 25px;
          margin-top: 27px;
          font-weight: ${FONT.REGULAR};
          font-size: ${SIZE.NORMAL};
          /* line-height: 18px; */
          color: ${COLORS.PLACEHOLDER_GREY};
          text-align: center;
          width: 100%;

          h1 {
               padding-top: 0;
          }
          
          &.bottom {
            border-top: 0;
            border-bottom: 1px solid ${COLORS.LIGHTER_BLUE};
            margin-bottom: 30px;
            color: ${COLORS.DARK_BLUE};
            
            div {
                margin-bottom: 30px;
            }
          }
     }
`;

export default AuthPageWrapper;
