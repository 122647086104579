import React, { memo, lazy, Suspense } from 'react';

import MobileToggler from '../../../common/mobile-toggler';
import MeatballMenu from '../../../common/meatball-menu';
import HeaderToolbarWrapper from './HeaderToolbar.style';

const ToolbarBody = lazy(() => import('./ToolbarBody'));

export const HeaderToolbar = memo(() => {
     const isAuth = window.location.pathname.includes('auth');
     const isPublicSpeakerForms = window.location.pathname.includes('speaker-form');

     return (
          <HeaderToolbarWrapper>
               {!isAuth && !isPublicSpeakerForms && <MobileToggler />}

               <MeatballMenu>
                    <HeaderToolbarWrapper>
                         <Suspense fallback={<></>}>
                              <ToolbarBody />
                         </Suspense>
                    </HeaderToolbarWrapper>
               </MeatballMenu>

               <Suspense fallback={<></>}>
                    <ToolbarBody notMobile />
               </Suspense>
          </HeaderToolbarWrapper>
     );
});
