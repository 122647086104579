import React, { useMemo, useEffect, useState, useCallback } from 'react';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import {Button, Alert} from 'react-bootstrap';
import MoreIcon from '../../../../assets/icons/metronic/more-icon';
import TrashCanIcon from '../../../../assets/icons/metronic/trash-can-icon';
import UserRegularIcon from '../../../../assets/icons/metronic/user-regular';
import { TablePanel } from '../../../common/filterable-table/TablePanel';
import {requestUpdateMemberScope, requestRemoveMember} from "../../../../redux/reducers/organizationReducer";
import SweetAlert from '../../../common/sweet-alert';

import FilterableTableWrapper from '../../../common/filterable-table/FilterableTable.style';
import {
     StyledDropdown,
     TableDropdownWrapper,
} from '../../../common/dropdown/Dropdown.style';
import Table from '../../../common/Table';
import { AddMembers } from './AddMembers';

const ActionsDropdown = ({ item, handleAction }) => {
     const [dropdownShown, setDropdownShown] = useState(false);

     const toggleDropdown = useCallback(() => {
          setDropdownShown(!dropdownShown);
     }, [dropdownShown]);

     return (
          <TableDropdownWrapper>
               <StyledDropdown
                    onToggle={toggleDropdown}
                    show={dropdownShown}
                    alignRight
               >
                    <StyledDropdown.Toggle variant="default">
                         <MoreIcon className="icon" />
                    </StyledDropdown.Toggle>
                    <StyledDropdown.Menu>
                         <StyledDropdown.Item
                              as="div"
                              style={{
                                   margin: 0,
                                   padding: 0,
                              }}
                         >
                              <Link to="#" className="dropdown-item">
                                   <UserRegularIcon />
                                   <span
                                        onClick={() =>
                                             handleAction({
                                                  id: item.user_id,
                                                  type: 'update',
                                             })
                                        }
                                   >
                                        {item.scope === 'admin'
                                             ? i18n.t(
                                                    'organization_members.make_member'
                                               )
                                             : i18n.t(
                                                    'organization_members.make_admin'
                                               )}
                                   </span>
                              </Link>
                         </StyledDropdown.Item>
                         <StyledDropdown.Item
                              as="div"
                              style={{
                                   margin: 0,
                                   padding: 0,
                              }}
                         >
                              <Link to="#" className="dropdown-item">
                                   <TrashCanIcon />
                                   <span
                                        className="remove"
                                        onClick={() =>
                                             handleAction({
                                                  id: item.user_id,
                                                  type: 'remove',
                                             })
                                        }
                                   >
                                        {i18n.t('buttons.remove')}
                                   </span>
                              </Link>
                         </StyledDropdown.Item>
                    </StyledDropdown.Menu>
               </StyledDropdown>
          </TableDropdownWrapper>
     );
};

const mapStateToProps = (state, ...ownProps) => ({
     ...ownProps,
     router: state.router,
     currentUser: state.userReducer.currentUser
});
const mapDispatchToProps = (dispatch) => ({
     requestUpdateMemberScope: (payload) => dispatch(requestUpdateMemberScope(payload)),
     requestRemoveMember: (payload) => dispatch(requestRemoveMember(payload))
});

export const _TeamMembersTable = ({ data, currentUser, updateCallback, ...props }) => {
     const {
          router: {
               location: { pathname },
          },
          handleSearch,
          pageMeta,
          handlePageSizeChange,
          handlePageChange,
          sorting,
          handleSort,
          isSearching,
          organization,
          requestUpdateMemberScope,
          requestRemoveMember
     } = props;

     const { t } = useTranslation();

     const columns = useMemo(
          () => [
               {
                    Header: i18n.t('organization_members.name'),
                    accessor: 'name',
               },
               {
                    Header: i18n.t('organization_members.email'),
                    accessor: 'email',
               },
               {
                    Header: i18n.t('organization_members.level'),
                    accessor: 'level',
               },
               {
                    Header: i18n.t('actions'),
                    accessor: 'actions',
               },
          ],
          []
     );

     const [transformedData, setTransformedData] = useState([]);
     const [showAddMemberModal, setShowAddMemberModal] = useState(false);

     const transformData = (data) =>
          data.map((item) => ({
               ...item,
               name: `${item.firstname} ${item.lastname}`,
               level: t(`organization_members.scope_${item.scope}`),
               actions: (
                    <ActionsDropdown
                         item={item}
                         handleAction={handleAction}
                         eventId={item.id}
                         updateCallback={updateCallback}
                    />
               ),
          }));

     useEffect(() => {
          setTransformedData(transformData(data));

          if (!transformedData) return;
     }, [data]);

     const handleAction = ({ id, type }) => {
          if (organization) {
               const foundUser = data && data.find(({ user_id }) => user_id === id);

               if (type === 'update') {
                    requestUpdateMemberScope({
                         organizationId: organization.id,
                         userId: id,
                         scope: foundUser.scope === 'admin' ? 'member' : 'admin',
                    });
               }

               if (type === 'remove') {
                    SweetAlert({
                         title: i18n.t('sweet_alerts.attention'),
                         text: `${i18n.t('sweet_alerts.want_to_remove')} ${foundUser.firstname} ${foundUser.lastname} ${i18n.t('sweet_alerts.from')} ${organization && organization.name}?`,
                         confirmButtonText: i18n.t('buttons.delete'),
                         cancelButtonText: i18n.t('buttons.cancel'),
                         showCancelButton: true,
                         callback: () => {
                              requestRemoveMember({
                                   organizationId: organization.id,
                                   userId: id
                              });
                              const updatedList = data && data.filter(({ user_id }) => user_id !== id);

                              return (
                                   updatedList && setTransformedData(transformData(updatedList))
                              );
                         },
                    });
               }
          }
     };

     return (
          <FilterableTableWrapper>
               <TablePanel
                    title={t('organization_members.all')}
                    total={pageMeta.total}
                    handleSearch={handleSearch}
                    actions={
                         <>
                              <Button variant="success" onClick={() => setShowAddMemberModal(true)}>
                                   {t('organization_members.add_new')}
                              </Button>
                              <AddMembers
                                   show={showAddMemberModal}
                                   setShowModal={setShowAddMemberModal}
                                   handleClose={() => setShowAddMemberModal(false)}
                                   organization={organization}
                              />
                         </>
                    }
               />
               
               {transformedData && transformedData.length > 0 && (
                    <Table
                         columns={columns}
                         data={transformedData}
                         pageMeta={pageMeta}
                         sorting={sorting}
                         handleSort={handleSort}
                         handlePageChange={handlePageChange}
                         handlePageSizeChange={handlePageSizeChange}
                    />
               )}
               {transformedData.length === 0 && (
                    <div className="events-empty overflow-hidden">
                    <div className="row">
                         <div className="col-sm-12 mb-5">
                              <div className="actions">
                                   <Alert variant={'outline-primary'}>
                                        {isSearching ? t('alerts.primary.organization_members_search_empty') : t('alerts.primary.organization_members_empty')}
                                   </Alert>
                              </div>
                         </div>
                    </div>
               </div>
               )} 
               
          </FilterableTableWrapper>
     );
};

export const TeamMembersTable = connect(mapStateToProps, mapDispatchToProps)(_TeamMembersTable);
