import instance, {getToken, getUrl} from './instance';

export const getAllRoles = async () => {
     try {
          return await instance.get(`${getUrl()}/user/admin/roles`);
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};
