import React from 'react';

import { CURRENT_YEAR } from '../../../utils/date.utils';

const SubFooter = () => {
     return (
          <div className="bg-theme-darker">
               <div className="container-standard">
                    <div className="subfooter-wrapper">
                         <div className="subfooter-logo">
                              © {CURRENT_YEAR} Avovent
                         </div>
                         <div>
                              <a href="https://app.avovent.com" target="_blank" className="btn btn-success mb-sm-0 mb-4">Go to app <i className="fas fa-arrow-right" /></a>
                         </div>
                    </div>
               </div>
          </div>
     );
};

export default SubFooter;
