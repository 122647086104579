import instance, {getToken, getUrl} from './instance';

// TODO: refactor later on
export const isAuthorized = async () => {
     try {
          return await instance.get(`${getUrl()}/user/auth/who`, {
               headers: {
                    common: {
                         Authorization:
                             `Bearer ${getToken()}` || 'Bearer token',
                    },
               },
          });
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const getUserProfile = async () => {
     try {
          return await instance.get(`${getUrl()}/user/profile`, {
               headers: {
                    common: {
                         Authorization:
                             `Bearer ${getToken()}` || 'Bearer token',
                    },
               },
          });
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const regenerateUserAvatar = async () => {
     try {
          const options = {
               headers: {
                    Authorization: `Bearer ${getToken()}` || 'Bearer token',
               },
          };

          const regenerationURL = `${getUrl()}/media/avatar/regenerate`;

          return await instance.post(regenerationURL, { ...options });
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const regenerateUserAvatarTemp = async (payload) => {
     try {
          const options = {
               headers: {
                    Authorization: `Bearer ${getToken()}` || 'Bearer token',
               },
          };

          return await instance.post(`${getUrl()}/media/avatar/generate/temp`, payload, { ...options });
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const userAvatarUpload = async (payload) => {
     const formData = new FormData();
     try {
          formData.append('avatar', payload.avatar);

          return await instance.post(
               `${getUrl()}/media/avatar`,
               formData,
               {
                    headers: {
                         Authorization:
                              `Bearer ${getToken()}` || 'Bearer token'
                    },
                    params: payload.crop
               }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const updateUserProfile = async (payload) => {
     try {
          return await instance.post(`${getUrl()}/user/profile`, payload, {
               headers: {
                    Authorization: `Bearer ${getToken()}` || 'Bearer token',
               },
          });
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const changeUserPassword = async (payload) => {
     try {
          return await instance.post(
               `${getUrl()}/user/profile/password`,
               payload,
               {
                    headers: {
                         Authorization:
                              `Bearer ${getToken()}` || 'Bearer token',
                    },
               }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const changeUserEmail = async (payload) => {
     try {
          return await instance.post(
              `${getUrl()}/user/profile/email`,
              payload,
              {
                   headers: {
                        Authorization:
                            `Bearer ${getToken()}` || 'Bearer token',
                   },
              }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const removeAvatar = async () => {
     try {
          return await instance.delete(`${getUrl()}/media/avatar`, {
               headers: {
                    common: {
                         Authorization:
                             `Bearer ${getToken()}` || 'Bearer token',
                    },
               },
          });
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const getAvailableLanguages = async () => {
     try {
          return await instance.get(`${getUrl()}/user/languages`);
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const getEverybody = async (payload) => {
     try {
          return await instance.get(
               `${getUrl()}/user/admin/users/all`,
              {
                   headers: {
                        common: {
                             Authorization:
                                 `Bearer ${getToken()}` || 'Bearer token',
                        },
                   }
              }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const getAllUsers = async (payload) => {
     try {
          if (payload.query) {
               return await instance.post(
                    `${getUrl()}/user/admin/users/search`,
                   { query: payload.query },
                   {
                        headers: {
                             Authorization:
                                 `Bearer ${getToken()}` || 'Bearer token',
                        },
                        params: {
                             per_page: payload.per_page,
                             page: payload.page,
                             sort: payload.sort,
                             direction: payload.direction
                        }
                   },
               );
          }

          return await instance.get(
               `${getUrl()}/user/admin/users`,
              {
                   headers: {
                        common: {
                             Authorization:
                                 `Bearer ${getToken()}` || 'Bearer token',
                        },
                   },
                   params: {
                        per_page: payload.per_page,
                        page: payload.page,
                        sort: payload.sort,
                        direction: payload.direction
                   }
              }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const createNewUser = async (payload) => {
     try {
          return await instance.put(`${getUrl()}/user/admin/users`, payload, {
               headers: {
                    Authorization: `Bearer ${getToken()}` || 'Bearer token',
               },
          });
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const updateUser = async (id, payload) => {
     try {
          return await instance.post(`${getUrl()}/user/admin/users/${id}`, payload, {
               headers: {
                    Authorization: `Bearer ${getToken()}` || 'Bearer token',
               },
          });
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const getUser = async (id) => {
     try {
          return await instance.get(`${getUrl()}/user/admin/users/${id}`, {
               headers: {
                    common: {
                         Authorization:
                             `Bearer ${getToken()}` || 'Bearer token',
                    },
               },
          });
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const removeUser = async ({ id }) => {
     try {
          return await instance.delete(`${getUrl()}/user/admin/users/${id}`, {
               headers: {
                    common: {
                         Authorization:
                             `Bearer ${getToken()}` || 'Bearer token',
                    },
               },
          });
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
}
