import React, {memo, useState, useRef, useEffect} from 'react';
import { DefaultSpinner } from '../../../common/spinner/DefaultSpinner';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Modal, Button } from 'react-bootstrap';
import TagField from '../../../common/tagfield';
import { requestUsersEverybody } from '../../../../redux/reducers/userReducer';
import { requestAddMember } from '../../../../redux/reducers/organizationReducer';

const mapStateToProps = (state, ...ownProps) => ({
     ...ownProps,
     router: state.router,
     users: state.userReducer.everybody,
     addMemberRequested: state.organizationReducer.addMemberRequested,
     addMemberSucceeded: state.organizationReducer.addMemberSucceeded,
     addMemberFailed: state.organizationReducer.addMemberFailed
});
const mapDispatchToProps = (dispatch) => ({
     requestUsersEverybody: () => dispatch(requestUsersEverybody()),
     requestAddMember: (payload) => dispatch(requestAddMember(payload)),
});

export const _AddMembers = memo(
     ({ requestUsersEverybody, requestAddMember, users, show, setShowModal, handleClose, organization, addMemberRequested, addMemberSucceeded, addMemberFailed }) => {
          const { t } = useTranslation();
          const [selectedUsers, setSelectedUsers] = useState('');

         useEffect(() => {
             // Disable scrolling on main page
             document.body.style.overflow = show ? 'hidden' : 'unset';

             if (show) {
               requestUsersEverybody();
             }
         }, [show]);

         useEffect(() => {
              if (addMemberSucceeded || addMemberFailed) {

               setShowModal(false);
              }
         }, [addMemberSucceeded, addMemberFailed]);

          const handleInviteSubmit = () => {
               requestAddMember({
                    organizationId: organization.id,
                    users: selectedUsers,
               });
          };

          const handleTagsChange = ({ target: { value } }) => {
               const userIds = value.split(',').map((v) => parseInt(v));
               setSelectedUsers(users.filter(((user) => userIds.includes(user.id))));
          };

          const transformSuggestions = (users) => {
               return users && users.
                         filter((user) => !user.organizations.hasOwnProperty(organization.id))
                         .map((user) => ({value: `${user.firstname} ${user.lastname}`, id: user.id }));
          };
     
          const suggestions = transformSuggestions(users);

          return (
               users && (
                    <Modal
                         show={show}
                         onHide={handleClose}
                         container={document.getElementById('root')}
                    >
                         <Modal.Header closeButton size="lg">
                              <Modal.Title>
                                   {t('organization_members.add_member_title')}
                              </Modal.Title>
                         </Modal.Header>
                         <Modal.Body>
                              <p>{t('organization_members.add_member_hint')}</p>
                              <Form.Group controlId="email">
                                   <TagField
                                        withIds
                                        placeholder={t('organization_members.add_member_placeholder')}
                                        value={''}
                                        suggestions={suggestions}
                                        onChange={handleTagsChange}
                                   />
                              </Form.Group>
                         </Modal.Body>
                         <Modal.Footer>
                              <Button variant="secondary" onClick={handleClose}>
                                   {t('buttons.close')}
                              </Button>
                              <Button
                                   variant="primary"
                                   type="submit"
                                   onClick={handleInviteSubmit}
                                   disabled={selectedUsers.length === 0 || addMemberRequested}
                              >
                                   {t('organization_members.add')}
                                   {<DefaultSpinner isLoading={addMemberRequested} />}
                              </Button>
                         </Modal.Footer>
                    </Modal>
               )
          );
     }
);

export const AddMembers = connect(
     mapStateToProps,
     mapDispatchToProps
)(_AddMembers);
