import React, {memo} from 'react';
import {Switch, Route} from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { HISTORY } from '../constants';

import AuthPage from './auth';
import { MainPage } from './main/MainPage';
import Header from './common/Header';
import NotFoundPage from './common/NotFound';
import NotSupportedPage from "./common/NotSupported";
import {validateSupport} from "../utils/browser.utils";
import smoothscroll from 'smoothscroll-polyfill';
import Subfooter from './common/Footer/SubFooter';
import FooterWrapper from './common/Footer/Footer.style';

smoothscroll.polyfill();

export const App = memo(() => {

     validateSupport();

     return (
         <main>
              <div className="App">
                   <ConnectedRouter history={HISTORY}>
                        <Switch>
                             <Route exact path="/not-found-404">
                                  <Header />
                                  <div className="content">
                                       <NotFoundPage />
                                  </div>
                                  <FooterWrapper>
                                        <Subfooter />
                                   </FooterWrapper>
                             </Route>
                             <Route exact path="/not-supported">
                                  <Header />
                                  <div className="content">
                                       <NotSupportedPage />
                                  </div>
                                  <FooterWrapper>
                                        <Subfooter />
                                   </FooterWrapper>
                             </Route>
                             <Route path="/auth">
                                   <div className="content">
                                        <AuthPage />
                                   </div>
                             </Route>
                             <Route path="/">
                                  <MainPage />
                             </Route>
                        </Switch>
                   </ConnectedRouter>
              </div>
         </main>
     );
});
