import styled from 'styled-components';
import {
     FLEX_ROW_CENTER,
     FONT,
     COLORS,
} from '../../../constants/style.constants';

const HeaderWrapper = styled.div`
     background-color: #fff;
     border-bottom: 1px solid ${COLORS.LIGHTER_BLUE};
     font-weight: ${FONT.REGULAR};
     padding-top: 31px;
     padding-bottom: 31px;
     z-index: 601;

     .container-standard {
          ${FLEX_ROW_CENTER};
     }

     @media only screen and (max-width: 1366px) and (min-width: 320px) {
          display: flex;
          flex-shrink: 0;
     }
`;

export default HeaderWrapper;
