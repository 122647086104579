import React from "react";
import { Link } from 'react-router-dom';
import {Button} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import LoadingBar from "../loading-bar";

export const Navigation = ({ activated = 'dashboard', loadingBar = null }) => {

    const { t } = useTranslation();
    
    return (
        <div className="content--border">
            <div className="container-standard">
                    <div class="nav">
                        <Link to="/dashboard" className={ (activated  !== 'dashboard' ? 'inactive' : '') + ' mr-2' }>
                            {
                                activated === 'dashboard' ? (
                                    <Button className="btn-themed-blue btn-navigational">
                                        {t('breadcrumb.dashboard')}
                                    </Button>
                                ) : t('breadcrumb.dashboard')
                            }
                        </Link>
                        <Link to="/users" className={ (activated  !== 'users' ? 'inactive' : '') + ' mr-2' }>
                            {
                                activated === 'users' ? (
                                    <Button className="btn-themed-blue btn-navigational">
                                        {t('breadcrumb.users')}
                                    </Button>
                                ) : t('breadcrumb.users')
                            }
                            
                        </Link>
                        <Link to="/organizations" className={ (activated  !== 'organizations' ? 'inactive' : '') + ' mr-2' }>
                            {
                                activated === 'organizations' ? (
                                    <Button className="btn-themed-blue btn-navigational">
                                        {t('breadcrumb.organizations')}
                                    </Button>
                                ) : t('breadcrumb.organizations')
                            }
                            
                        </Link>
                    </div>
            </div>
            {loadingBar !== null && (<LoadingBar finish={!!loadingBar} />)}
        </div>
    );

};
