export const isSupportedBrowser = () => {
     const ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
     const msie = ua.indexOf('MSIE '); // IE 10 or older
     const trident = ua.indexOf('Trident/'); //IE 11

     return !(msie > 0 || trident > 0);
};

export const isNotSupportedPage = () => {
     return window.location.pathname === '/not-supported';
};

export const validateSupport = () => {
     if (!isSupportedBrowser() && !isNotSupportedPage()) {
          window.location.replace('/not-supported');
     }
};
