import React, { memo, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';
import { requestUserAuthorized } from '../../redux/reducers/userReducer';
import { LoginBlockForm } from './forms/LoginBlockForm';
import { ResetPasswordForm } from './forms/ResetPasswordForm';

import Header from '../common/Header';
import Subfooter from '../common/Footer/SubFooter';
import FooterWrapper from '../common/Footer/Footer.style';
import AuthWrapper from './auth-styles/AuthPage.style';
import AuthPageWrapper from './auth-styles/AuthPageWrapper.style';

const EnhancedLoginRoute = memo(({ loggedIn }) => {
     const history = useHistory();

     useEffect(() => {
          if (loggedIn) {
               history.replace('/dashboard');
          }
     }, [history, loggedIn]);

     return !loggedIn && <LoginBlockForm />;
});

export const AuthPage = memo(() => {
     const dispatch = useDispatch();
     const authorized = useSelector((state) => state.userReducer.authorized);
     const { pathname } = useLocation();

     useEffect(() => {
          if (pathname === '/auth/login') {
               dispatch(requestUserAuthorized());
          }
     }, [pathname]);

     const isAuthReady = useMemo(() => {
          if (pathname === '/auth/login') {
               return authorized !== null;
          }

          return true;
     }, [pathname, authorized]);

     return (
          isAuthReady && (
               <Switch>
                    <Route path="/auth">
                         <Header />
                         <AuthWrapper>
                              <AuthPageWrapper>
                                   <Switch>
                                        <Route path="/auth/login">
                                             <EnhancedLoginRoute loggedIn={authorized} />
                                        </Route>
                                        <Route path="/auth/reset-password/:resetToken">
                                             <ResetPasswordForm />
                                        </Route>
                                        <Route path="*">
                                             <LoginBlockForm />
                                        </Route>
                                   </Switch>
                              </AuthPageWrapper>
                         </AuthWrapper>
                         <FooterWrapper>
                              <Subfooter />
                         </FooterWrapper>
                    </Route>
               </Switch>
          )
     );
});
