import React, { lazy } from "react";
import { useTranslation } from 'react-i18next';
import { ListItem } from "../collapsible-list/CollapsibleList";

const MainDrawer = lazy(() => import('../../common/drawer'));

export const MobileNavigation = () => {

    const { t } = useTranslation();
    
    return (
        <MainDrawer>
            <ListItem to="/dashboard">
                    {t('breadcrumb.dashboard')}
            </ListItem>
            <ListItem to="/users">
                    {t('breadcrumb.users')}
            </ListItem>
            <ListItem to="/organizations">
                    {t('breadcrumb.organizations')}
            </ListItem>
        </MainDrawer>
    );

};
