import i18n from 'i18next';
import toastr from 'toastr';
import {call, put, select, all, takeEvery} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
     successAllOrganizations,
     failAllOrganizations,
     setOrganizationsMeta,
     successNewOrganization,
     failNewOrganization,
     successUpdateOrganization,
     failUpdateOrganization,
     successGetOrganization,
     failGetOrganization,
     successOrganizationMembers,
     failOrganizationMembers,
     setOrganizationMembersMeta,
     successUpdateMemberScope,
     failUpdateMemberScope,
     successRemoveMember,
     failRemoveMember,
     failAddMember,
     successAddMember,
     successAllOrganizationTiers,
     failAllOrganizationTiers,
     successRemoveOrganization,
     failRemoveOrganization
} from '../reducers/organizationReducer';
import * as ACTIONS from '../actions';
import * as Service from '../../services';
import {errorsDisplay} from "../reducers/errorsReducer";


export function* allOrganizationsSaga({ payload }) {
     const response = yield call(Service.getAllOrganizations, payload);

     if (response.status === 200) {
          const { data, ...pageMeta } = response.data;
          yield put(setOrganizationsMeta(pageMeta));
          return yield put(successAllOrganizations(data));
     }

     yield put(failAllOrganizations(response.message));
}

export function* allOrganizationTiersSaga({ payload }) {
     const response = yield call(Service.getAllTiers, payload);

     if (response.status === 200) {
          const { data } = response.data;
          return yield put(successAllOrganizationTiers(data));
     }

     yield put(failAllOrganizationTiers(response.message));
}

export function* createNewOrganization({ payload }) {
     const response = yield call(Service.createNewOrganization, payload);

     if (response.status === 201) {
          toastr.success(i18n.t('alerts.success.organization_have_been_created'));

          yield put(successNewOrganization(response.data.data));
          return yield put(push('/organizations'));
     }

     if (response.status === 400 || response.status === 422) {
          yield put(errorsDisplay({ errors: response.data.errors, message: response.data.message }));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     yield put(failNewOrganization(response.message));
}

export function* updateOrganization({ payload }) {
     const { id, ...rest } = payload;
     const response = yield call(Service.updateOrganization, id, rest);

     if (response.status === 200) {
          toastr.success(i18n.t('alerts.success.organization_have_been_updated'));

          return yield put(successUpdateOrganization(response.data.data));
     }

     if (response.status === 400 || response.status === 422) {
          yield put(errorsDisplay({ errors: response.data.errors, message: response.data.message }));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     yield put(failUpdateOrganization(response.message));
}

export function* removeOrganization({ payload }) {
     const response = yield call(Service.removeOrganization, payload);

     if (response.status === 200) {
          toastr.success(i18n.t('alerts.success.organization_have_been_removed'));

          return yield put(successRemoveOrganization(response.data.data));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     yield put(failRemoveOrganization(response.message));
}

export function* getOrganization({ payload }) {
     const response = yield call(Service.getOrganization, payload);

     if (response.status === 200) {
          return yield put(successGetOrganization(response.data.data));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     yield put(failGetOrganization(response.message));
}

export function* getOrganizationMembers({ payload }) {
     const response = yield call(Service.getOrganizationMembers, payload);

     if (response.status === 200) {
          const { data, ...pageMeta } = response.data;
          yield put(setOrganizationMembersMeta(pageMeta));
          return yield put(successOrganizationMembers(data));
     }

     yield put(failOrganizationMembers(response.message));
}

export function* updateMemberScope({ payload }) {
     const response = yield call(Service.updateMemberScope, payload);

     if (response.status === 200) {
          return yield put(successUpdateMemberScope(response.data.data));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     yield put(failUpdateMemberScope(response.message));
}

export function* removeMember({ payload }) {
     const response = yield call(Service.removeMember, payload);

     if (response.status === 200) {
          toastr.success(i18n.t('alerts.success.organization_member_have_been_removed'));

          return yield put(successRemoveMember(response.data.data));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     yield put(failRemoveMember(response.message));
}

export function* addMember({ payload }) {
     const response = yield call(Service.addMembers, payload);

     if (response.status === 200) {
          toastr.success(i18n.t('alerts.success.organization_members_have_been_added'));
          yield call(getOrganizationMembers.bind(null, { payload: { id: payload.organizationId, per_page: 50 } }));

          return yield put(successAddMember(response.data.data));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     yield put(failAddMember(response.message));
}

export function* watchOrganization() {
     yield all([
          takeEvery(ACTIONS.ALL_ORGANIZATIONS_REQUEST, allOrganizationsSaga),
          takeEvery(ACTIONS.NEW_ORGANIZATION_REQUEST, createNewOrganization),
          takeEvery(ACTIONS.UPDATE_ORGANIZATION_REQUEST, updateOrganization),
          takeEvery(ACTIONS.REMOVE_ORGANIZATION_REQUEST, removeOrganization),
          takeEvery(ACTIONS.GET_ORGANIZATION_REQUEST, getOrganization),
          takeEvery(ACTIONS.ORGANIZATION_MEMBERS_REQUEST, getOrganizationMembers),
          takeEvery(ACTIONS.UPDATE_MEMBER_SCOPE_REQUEST, updateMemberScope),
          takeEvery(ACTIONS.REMOVE_MEMBER_REQUEST, removeMember),
          takeEvery(ACTIONS.ADD_MEMBER_REQUEST, addMember),
          takeEvery(ACTIONS.ALL_ORGANIZATION_TIERS_REQUEST, allOrganizationTiersSaga)
     ])
}
