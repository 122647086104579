import styled from 'styled-components';
import {
     COLORS,
     FORM_TEXT,
     FORM_PLACEHOLDER,
     SIZE,
} from '../../../constants/style.constants';

const UserSettingsWrapper = styled.div`
     /* position: relative; */

     .row {
          align-items: center;
     }

     .file_upload {
          align-items: start !important;

          .col-3 {
               margin-top: 11px;
          }
     }

     form {
          margin: 60px auto;
          ${FORM_TEXT};
          input::placeholder {
               ${FORM_PLACEHOLDER};
               color: ${COLORS.PLACEHOLDER_GREY};
          }

          .col-3 {
               label {
                    margin: 0;
               }
          }

          .form-control {
               font-family: 'IBM Plex Sans', sans-serif;
               font-size: ${SIZE.NORMAL};
               height: calc(1.5em + 1.3rem + 2px);
               line-height: 1.5;
               padding: 9px 14px;
               
               &.is-invalid {
                    padding-right: 30px;
               }
          }

          input[type='email'] {
               &.is-invalid {
                    background-position: right calc(0.375em + 0.1875rem) top
                         calc(1.5rem - 0.75rem + 1px);
               }
          }

          .body-divider {
               margin: 20px 0 25px;
          }

          .form-actions {
               display: flex;
               flex-direction: row-reverse;
          }

          .btn {
               margin: 0;
          }
     }

     @media only screen and (max-width: 1023px) {
          p,
          .form-control,
          input::placeholder {
               font-size: ${SIZE.TINY} !important;
          }
     }
`;

export default UserSettingsWrapper;
