import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { MobileNavigation, Navigation } from '../../../common/navigation';

import Breadcrumb from '../../../common/breadcrumb';
import UserForm from './UserForm';
import {requestAvailableRoles} from "../../../../redux/reducers/roleReducer";
import {requestNewUser} from "../../../../redux/reducers/userReducer";

import UsersWrapper from '../User.style';

const mapStateToProps = (state) => ({
     roles: state.roleReducer.roles
});
const mapDispatchToProps = (dispatch) => ({
     requestAvailableRoles: () => dispatch(requestAvailableRoles()),
     requestNewUser: (values) => dispatch(requestNewUser(values))
});

const _NewUser = (props) => {
     const { t } = useTranslation();

     const { roles, requestAvailableRoles, requestNewUser} = props;

     const breadcrumbLinks = [
          { to: '/dashboard', text: t('breadcrumb.dashboard') },
          { to: '/users', text: t('breadcrumb.users') },
          { to: '/users/new', text: t('breadcrumb.users_new') }
     ];

     const handleCreateNewUser = (values) => {
          requestNewUser(values);
     };

     useEffect(() => {
          requestAvailableRoles();
     }, []);

     return (
          <UsersWrapper>
               <Helmet>
                    <title>
                         {t('breadcrumb.users_new')} | {t('avovent')}
                    </title>
               </Helmet>
               <MobileNavigation />
               <Navigation activated='users' loadingBar={!!roles} />
               <Breadcrumb title={t('breadcrumb.users')} links={breadcrumbLinks} />


               <div className="new-user">
                    <div className="container-standard">
                         <div className="user-content-body card">
                              <h3 className="card-title">
                                   {t('users.new_user')}
                              </h3>
                              <section>
                                   <div className="card-body">
                                        <UserForm
                                             btnText={t('buttons.create')}
                                             roles={roles}
                                             handleSave={handleCreateNewUser}
                                        />
                                   </div>
                              </section>
                         </div>
                    </div>
               </div>
          </UsersWrapper>
     );
};

export const NewUser = connect(mapStateToProps, mapDispatchToProps)(_NewUser);
