import React, { memo, useCallback, lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Logo from '../../common/logo';
import HeaderWrapper from './Header.style';
import HeaderToolbar from './header-toolbar';

const HeaderToolbarWrapper = lazy(() =>
     import('./header-toolbar/HeaderToolbar.style')
);
const ToolbarBody = lazy(() => import('./header-toolbar/ToolbarBody'));

export const Header = memo(() => {
     const { pathname } = useLocation();
     const isNotFound = pathname === '/not-found-404';

     const { toolbarShown } = useSelector((state) => state.ui);

     const renderHeader = () => (
          <>
               <HeaderWrapper>
                    <div className="container-standard">
                         <Logo />
                         <HeaderToolbar />
                    </div>
               </HeaderWrapper>
               <Suspense fallback={<></>}>
                    <div
                         className={`toolbar-expanded container-standard ${
                              toolbarShown ? 'toolbar-shown' : ''
                         }`}
                    >
                         <HeaderToolbarWrapper>
                              <ToolbarBody />
                         </HeaderToolbarWrapper>
                    </div>
               </Suspense>
          </>
     );

     return !isNotFound && renderHeader();
});
