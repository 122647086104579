import {call, put, all, takeEvery} from 'redux-saga/effects';
import {
     successAvailableRoles,
     failAvailableRoles,
} from '../reducers/roleReducer';
import * as ACTIONS from '../actions';
import * as Service from '../../services';

export function* availableRolesSaga() {
     const response = yield call(Service.getAllRoles);

     if (response.status === 200) {
          return yield put(successAvailableRoles(response.data.data));
     }

     yield put(failAvailableRoles(response.message));
}

export function* watchRole() {
     yield all([
          takeEvery(ACTIONS.AVAILABLE_ROLES_REQUEST, availableRolesSaga),
     ])
}
