import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import authReducer from './authReducer';
import userReducer from './userReducer';
import roleReducer from './roleReducer';
import customReducer from './customReducer';
import uiReducer from './uiReducer';
import errorsReducer from './errorsReducer';
import organizationReducer from './organizationReducer';

const rootReducer = (history) =>
     combineReducers({
          router: connectRouter(history),
          authReducer,
          userReducer,
          roleReducer,
          customReducer,
          ui: uiReducer,
          errorsReducer,
          organizationReducer
     });

export default rootReducer;
