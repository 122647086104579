import React, {useEffect, useRef} from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Button, Row, Col, Form } from 'react-bootstrap';
import { CHANGE_EMAIL } from '../../../../constants/form.constants';
import {
     requestChangeUserEmail,
     requestUserProfile,
} from '../../../../redux/reducers/userReducer';
import {errorsRefresh} from "../../../../redux/reducers/errorsReducer";
import {Input} from "../../../common/input/Input";
import { DefaultSpinner } from '../../../common/spinner/DefaultSpinner';

const mapStateToProps = (state) => ({
     router: state.router,
     user: state.userReducer,
     errors: state.errorsReducer.errors
});

const mapDispatchToProps = (dispatch) => ({
     requestUserProfile: () => dispatch(requestUserProfile()),
     requestChangeUserEmail: (values) => dispatch(requestChangeUserEmail(values)),
     refreshErrors: () => dispatch(errorsRefresh())
});

const _ChangeEmailForm = (props) => {
     const {
          user: { currentUser, changeUserEmailRequested, changeUserEmailSucceeded },
          router: {
               location: { pathname },
          },
          requestUserProfile,
          requestChangeUserEmail,
          errors,
          refreshErrors
     } = props;

     const { t } = useTranslation();
     const form = useRef();

     const handleChangeEmailSubmit = (values) => requestChangeUserEmail(values);

     const isEmailTooLong = (email) => {
          return email.length > 256;
     };

     useEffect(() => {
          if (errors && Object.keys(errors).length > 0) {
               form.current.setSubmitting(false);
               form.current.setErrors(errors);

               refreshErrors();
          }
     }, [errors]);

     useEffect(() => {
          if (!currentUser) {
               requestUserProfile();
          }
     }, [pathname, requestUserProfile]);

     useEffect(() => {
          if (changeUserEmailSucceeded) {
               form.current.resetForm();
          }
     }, [changeUserEmailSucceeded]);

     return currentUser && (
          <Formik
               innerRef={form}
               validationSchema={CHANGE_EMAIL.VALIDATION}
               onSubmit={handleChangeEmailSubmit}
               initialValues={{...CHANGE_EMAIL.INITIAL_VALUES, email: currentUser.email}}
               validateOnBlur={false}
               enableReinitialize={true}
          >
               {({ handleSubmit, handleChange, values, touched, errors }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                         <Form.Group as={Row} controlId="current">
                              <Col sm={3}>
                                   <Form.Label>
                                        {t(
                                             'user_settings.change_email_form.labels.current_email'
                                        )}
                                   </Form.Label>
                              </Col>
                              <Col sm={9}>
                                   <Input
                                        type="email"
                                        field="email"
                                        values={values}
                                        placeholder={t(
                                             'user_settings.change_email_form.placeholders.current_email'
                                        )}
                                        handleChange={(e) => {
                                             if (!isEmailTooLong(e.target.value)) {
                                                  handleChange(e);
                                             }
                                        }}
                                        touched={touched}
                                        errors={errors}
                                        disabled={changeUserEmailRequested}
                                   />
                              </Col>
                         </Form.Group>
                         <Form.Group as={Row} controlId="email_new">
                              <Col sm={3}>
                                   <Form.Label>
                                        {t(
                                             'user_settings.change_email_form.labels.new_email'
                                        )}
                                   </Form.Label>
                              </Col>
                              <Col sm={9}>
                                   <Input
                                        type="email"
                                        field="email_new"
                                        values={values}
                                        placeholder={t(
                                             'user_settings.change_email_form.placeholders.email_new'
                                        )}
                                        handleChange={(e) => {
                                             if (!isEmailTooLong(e.target.value)) {
                                                  handleChange(e);
                                             }
                                        }}
                                        touched={touched}
                                        errors={errors}
                                        disabled={changeUserEmailRequested}
                                   />
                              </Col>
                         </Form.Group>
                         <Form.Group as={Row} controlId="email_confirmation">
                              <Col sm={3}>
                                   <Form.Label>
                                        {t(
                                             'user_settings.change_email_form.labels.repeat_email'
                                        )}
                                   </Form.Label>
                              </Col>
                              <Col sm={9}>
                                   <Input
                                        type="email"
                                        field="email_new_confirmation"
                                        values={values}
                                        placeholder={t(
                                             'user_settings.change_email_form.placeholders.email_repeat'
                                        )}
                                        handleChange={(e) => {
                                             if (!isEmailTooLong(e.target.value)) {
                                                  handleChange(e);
                                             }
                                        }}
                                        touched={touched}
                                        errors={errors}
                                        disabled={changeUserEmailRequested}
                                   />
                              </Col>
                         </Form.Group>
                         <hr className="body-divider" />
                         <div className="form-actions">
                              <Button
                                   variant="success"
                                   size="sm"
                                   type="submit"
                                   disabled={changeUserEmailRequested}
                              >
                                   {t(
                                        'user_settings.change_email_form.button'
                                   )}
                                   {<DefaultSpinner isLoading={changeUserEmailRequested} />}
                              </Button>
                         </div>
                    </Form>
               )}
          </Formik>
     );
};

export const ChangeEmailForm = connect(
     mapStateToProps,
     mapDispatchToProps
)(_ChangeEmailForm);
