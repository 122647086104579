/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { connect } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Formik } from 'formik';
import {
    requestForgottenPassword,
} from '../../../redux/reducers/authReducer';
import { FORGOT_PASSWORD } from '../../../constants/form.constants';
import { FormWrapper } from '../auth-styles/AuthPage.style';

const mapStateToProps = (state) => ({
    auth: state.authReducer,
    router: state.router,
});

const mapDispatchToProps = (dispatch) => ({
    requestForgottenPassword: (values) =>
        dispatch(requestForgottenPassword(values)),
});

const _ForgotPasswordForm = (props) => {
    const {
        auth: { forgottenPasswordRequested },
        router: {
            location: { pathname },
        },
        requestForgottenPassword,
        toggleTransition
    } = props;

    const { t } = useTranslation();


    const handleForgotPasswordSubmit = (values, formikBag) => {
        requestForgottenPassword(values);

        formikBag.resetForm();
        toggleTransition();
    };

    return (
        <>
            {window.location.hash === '#forgot-password' && (
                <Helmet>
                    <title>
                        {t('forgot_password.header')} | {t('avovent')}
                    </title>
                </Helmet>
            )}
            <Formik
                validationSchema={FORGOT_PASSWORD.VALIDATION}
                initialValues={FORGOT_PASSWORD.INITIAL_VALUES}
                onSubmit={handleForgotPasswordSubmit}
                validateOnBlur={false}
            >
                {({
                      handleSubmit,
                      handleChange,
                      values,
                      touched,
                      errors,
                  }) => (
                    <FormWrapper>
                        <h1>{t('forgot_password.header')}</h1>
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group controlId="email">
                                <Form.Control
                                    className="form-row-wrap"
                                    type="email"
                                    name="email"
                                    placeholder={t(
                                        'forgot_password.placeholder'
                                    )}
                                    value={values.email}
                                    onChange={handleChange}
                                    isInvalid={
                                        touched.email &&
                                        !!errors.email
                                    }
                                    disabled={
                                        forgottenPasswordRequested
                                    }
                                />
                                <Form.Control.Feedback type="invalid">
                                    {touched.email && errors.email}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <hr className="divider" />
                            <div className="form-actions">
                                <Link to={pathname}>
                                    <Button
                                        type="button"
                                        variant="default"
                                        onClick={toggleTransition}
                                    >
                                        {t(
                                            'forgot_password.buttons.back'
                                        )}
                                    </Button>
                                </Link>
                                <Button
                                    type="submit"
                                    disabled={
                                        forgottenPasswordRequested
                                    }
                                >
                                    {t(
                                        'forgot_password.buttons.reset'
                                    )}
                                </Button>
                            </div>
                        </Form>
                    </FormWrapper>
                )}
            </Formik>
        </>
    );
};

export const ForgotPasswordForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(_ForgotPasswordForm);
