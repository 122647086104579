import React, { memo } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next'
import {Alert} from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import DashboardWrapper from './Dashboard.style';
import Breadcrumb from '../../common/breadcrumb';
import Navigation, { MobileNavigation } from '../../common/navigation';

const mapStateToProps = (state, ownProps) => ({ ...ownProps });
const mapDispatchToProps = (dispatch) => ({});

export const _Dashboard = memo((props) => {
     const { t } = useTranslation();

     const breadcrumbLinks = [{ to: '/dashboard', text: t('breadcrumb.dashboard') }];

     return (
          <DashboardWrapper>
               <Helmet>
                    <title>
                         {t('breadcrumb.dashboard')} | {t('avovent')}
                    </title>
               </Helmet>
               
               <MobileNavigation />
               <Navigation />

               <Breadcrumb
                    title={t('breadcrumb.dashboard')}
                    links={breadcrumbLinks}
               />

               <div className="container-standard">
                    <div className="row">
                         <div className="col-12">
                              <Alert
                                   key={'no-speakers-and-organizations'}
                                   variant={'outline-primary'}
                              >
                                   {t('welcome_to_administration')}
                              </Alert>
                         </div>
                    </div>
               </div>
               
          </DashboardWrapper>
     );
});

export const Dashboard = connect(
     mapStateToProps,
     mapDispatchToProps
)(_Dashboard);
