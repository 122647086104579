import './polyfills';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/stable';
import './i18n';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@yaireo/tagify/dist/tagify.css';
import GlobalStyles from './GlobalStyles';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './redux/configureStore';

import App from './components';

const store = configureStore();

ReactDOM.render(
     <Provider store={store}>
          <Suspense fallback={<></>}>
               <App />
          </Suspense>
          <GlobalStyles />
     </Provider>,
     document.getElementById('root')
);
