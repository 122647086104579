import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import Alert from '../../common/alert';
import picture from '../../../assets/images/illustration-ie.svg';
import logo from '../../../assets/images/logo_bg-white.svg';
import NotSupportedWrapper from './NotSupportedPage.style';

export const NotSupportedPage = memo(() => {
     const { t } = useTranslation();

     return (
          <section className="container-standard content">
               <Helmet>
                    <title>{t('avovent')}</title>
               </Helmet>
               <NotSupportedWrapper>
                    <img src={logo} alt={t('logo')} className="logo" />
                    <h1>{t('not_supported.header')}</h1>
                    <Alert>
                         {t('not_supported.info.paragraph1')} {t('not_supported.info.paragraph2')} <br />
                         {t('not_supported.info.paragraph3')}

                    </Alert>
                    <div className="btn-container">
                         <a target="_blank" rel="noopener noreferrer" className="btn btn-default" href="https://www.microsoft.com/en-us/edge">
                              {t('not_supported.edge')}
                         </a>
                         <a target="_blank" rel="noopener noreferrer" className="btn btn-default" href="https://www.google.com/chrome/">
                              {t('not_supported.edge')}
                         </a>
                    </div>
                    <img
                         src={picture}
                         alt={t('removal_notifications.picture')}
                         className="picture"
                    />
               </NotSupportedWrapper>
          </section>
     );
});
