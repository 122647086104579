import React, {useState, useEffect, useRef} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Row, Col, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import Input from '../../../common/input';
import TagField from '../../../common/tagfield';
import { EVENT } from '../../../../constants/form.constants';
import {errorsRefresh} from "../../../../redux/reducers/errorsReducer";
import { DefaultSpinner } from '../../../common/spinner/DefaultSpinner';

const mapStateToProps = (state, ownProps) => ({
     ...ownProps,
     router: state.router,
     newUserRequested: state.userReducer.newUserRequested,
     updateUserRequested: state.userReducer.updateUserRequested,
     errors: state.errorsReducer.errors
});

const mapDispatchToProps = (dispatch) => ({
     refreshErrors: () => dispatch(errorsRefresh())
});

const UserForm = ({ btnText, ...props }) => {
     const {
          user,
          roles,
          handleSave,
          isEditing = false,
          newUserRequested,
          updateUserRequested,
          errors,
          refreshErrors
     } = props;
     
     const { t } = useTranslation();
     const form = useRef();
     const handleSubmit = (values) => handleSave({ ...values, roles: selectedRoles });

     const [selectedRoles, setSelectedRoles] = useState(null); 

     const handleTagsChange = ({ target: { value } }) => {
          setSelectedRoles(value.split(',').map((v) => parseInt(v)));
     };

     useEffect(() => {
          if (errors && Object.keys(errors).length > 0) {
               form.current.setSubmitting(false);
               form.current.setErrors(errors);

               refreshErrors();
          }
     }, [errors]);

     const transformSuggestions = (roles) => {
          return (
               roles &&
               roles.map(
                    ({ id, name }) => ({
                         value: name,
                         id: id,
                    })
               )
          );
     };

     const suggestions = transformSuggestions(roles);
     const transformExistingRoles = (roles) => roles && roles.map(({ name }) => name).join(', ');

     useEffect(() => {
          if (user && user.roles) {
               setSelectedRoles(user.roles.map((u) => u.id));
          }
     }, [user]);

     return (
          <Formik
                    validationSchema={EVENT.VALIDATION}
                    enableReinitialize
                    onSubmit={handleSubmit}
                    initialValues={{
                         firstname: user && user.firstname,
                         lastname: user && user.lastname,
                         email: user && user.email,
                         roles: [],
                         password: '',
                         password_confirmation: ''
                    }}
                    validateOnBlur={false}
                    innerRef={form}
               >
                    {({
                         handleSubmit,
                         handleChange,
                         values,
                         touched,
                         errors
                    }) => (
                         <Form noValidate onSubmit={handleSubmit}>
                              <Form.Group as={Row} controlId="firstname">
                                   <Col sm={3}>
                                        <Form.Label>
                                             {t('users.first_name')}
                                        </Form.Label>
                                   </Col>
                                   <Col sm={9}>
                                        <Input
                                             type="text"
                                             field="firstname"
                                             values={values}
                                             placeholder={t('users.first_name_placeholder')}
                                             handleChange={handleChange}
                                             touched={touched}
                                             errors={errors}
                                        />
                                   </Col>
                              </Form.Group>
                              <Form.Group as={Row} controlId="lastname">
                                   <Col sm={3}>
                                        <Form.Label>
                                             {t('users.last_name')}
                                        </Form.Label>
                                   </Col>
                                   <Col sm={9}>
                                        <Input
                                             type="text"
                                             field="lastname"
                                             values={values}
                                             placeholder={t('users.last_name_placeholder')}
                                             handleChange={handleChange}
                                             touched={touched}
                                             errors={errors}
                                        />
                                   </Col>
                              </Form.Group>
                              <Form.Group as={Row} controlId="email">
                                   <Col sm={3}>
                                        <Form.Label>
                                             {t('users.email')}
                                        </Form.Label>
                                   </Col>
                                   <Col sm={9}>
                                        <Input
                                             type="text"
                                             field="email"
                                             values={values}
                                             placeholder={t('users.email_placeholder')}
                                             handleChange={handleChange}
                                             touched={touched}
                                             errors={errors}
                                        />
                                   </Col>
                              </Form.Group>
                              <div className="relative">
                                   <Form.Group
                                        as={Row}
                                        controlId="roles"
                                   >
                                        <Col sm={3}>
                                             <Form.Label>
                                                  {t('users.roles')}
                                             </Form.Label>
                                        </Col>
                                        <Col sm={9}>
                                             {roles && (
                                                  <>
                                                  <TagField
                                                       withIds
                                                       placeholder={t('users.roles_placeholder')}
                                                       value={user && transformExistingRoles(user.roles)}
                                                       suggestions={suggestions}
                                                       onChange={handleTagsChange}
                                                  />
                                                  <Form.Control.Feedback
                                                       type="invalid"
                                                       style={{ marginTop: '2px', display: 'block' }}
                                                  >
                                                       {errors['roles']}
                                                  </Form.Control.Feedback> 
                                                  </>
                                             )}
                                             <div className="info-block">
                                                  {t('users.select_all_roles')}
                                             </div>
                                        </Col>
                                   </Form.Group>
                              </div>

                              <Form.Group as={Row} controlId="password">
                                   <Col sm={3}>
                                        <Form.Label>
                                             {t('users.password')}
                                        </Form.Label>
                                   </Col>
                                   <Col sm={9}>
                                        <Input
                                             type="password"
                                             field="password"
                                             values={values}
                                             placeholder={t('users.password_placeholder')}
                                             handleChange={handleChange}
                                             touched={touched}
                                             errors={errors}
                                        />
                                        {isEditing && (
                                             <div className="info-block">
                                                  {t('users.password_change_explain')}
                                             </div>
                                        )}
                                   </Col>
                              </Form.Group>

                              <Form.Group as={Row} controlId="password_confirmation">
                                   <Col sm={3}>
                                        <Form.Label>
                                             {t('users.password_confirmation')}
                                        </Form.Label>
                                   </Col>
                                   <Col sm={9}>
                                        <Input
                                             type="password"
                                             field="password_confirmation"
                                             values={values}
                                             placeholder={t('users.password_confirmation_placeholder')}
                                             handleChange={handleChange}
                                             touched={touched}
                                             errors={errors}
                                        />
                                   </Col>
                              </Form.Group>

                              <hr className="body-divider" />

                              <div className="form-actions">
                                   <Link to={`/users`}>
                                        <Button variant="default">
                                             {t('buttons.back')}
                                        </Button>
                                   </Link>
                                   <Button
                                        variant="success"
                                        size="sm"
                                        type="submit"
                                        disabled={newUserRequested || updateUserRequested}
                                   >
                                        {btnText}
                                        {<DefaultSpinner isLoading={newUserRequested || updateUserRequested} />}
                                   </Button>
                              </div>
                         </Form>
                    )}
               </Formik>
     );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
