import * as ACTIONS from '../actions';

export const requestAvailableRoles = () => ({
     type: ACTIONS.AVAILABLE_ROLES_REQUEST,
});

export const successAvailableRoles = (payload) => ({
     type: ACTIONS.AVAILABLE_ROLES_SUCCESS,
     payload,
});

export const failAvailableRoles = (error) => ({
     type: ACTIONS.AVAILABLE_ROLES_FAILURE,
     error,
});

export const INITIAL_STATE = {
     availableRolesRequested: false,
     availableRolesSucceeded: false,
     availableRolesFailed: false,
     roles: null,
};

export default function roleReducer(state = INITIAL_STATE, action) {
     switch (action.type) {
          case ACTIONS.AVAILABLE_ROLES_REQUEST:
               return {
                    ...state,
                    availableRolesRequested: true,
                    availableRolesSucceeded: false,
                    availableRolesFailed: false,
               };
          case ACTIONS.AVAILABLE_ROLES_SUCCESS:
               return {
                    ...state,
                    availableRolesRequested: false,
                    availableRolesSucceeded: true,
                    availableRolesFailed: false,
                    roles: action.payload,
                    error: null,
               };
          case ACTIONS.AVAILABLE_ROLES_FAILURE:
               return {
                    ...state,
                    availableRolesRequested: false,
                    availableRolesSucceeded: false,
                    availableRolesFailed: true,
                    error: action.error,
                    languages: null,
               };
          default:
               return state;
     }
}
