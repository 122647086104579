import instance, {getToken, getUrl} from './instance';

export const getAllOrganizations = async (payload) => {
     try {
          if (payload.query) {
               return await instance.post(
                    `${getUrl()}/organization/admin/organizations/search`,
                   { query: payload.query },
                   {
                        headers: {
                             Authorization:
                                 `Bearer ${getToken()}` || 'Bearer token',
                        },
                        params: {
                             per_page: payload.per_page,
                             page: payload.page,
                             sort: payload.sort,
                             direction: payload.direction
                        }
                   },
               );
          }

          return await instance.get(
               `${getUrl()}/organization/admin/organizations`,
              {
                   headers: {
                        common: {
                             Authorization:
                                 `Bearer ${getToken()}` || 'Bearer token',
                        },
                   },
                   params: {
                        per_page: payload.per_page,
                        page: payload.page,
                        sort: payload.sort,
                        direction: payload.direction
                   }
              }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const createNewOrganization = async (payload) => {
     try {
          return await instance.put(`${getUrl()}/organization/admin/organizations`, payload, {
               headers: {
                    Authorization: `Bearer ${getToken()}` || 'Bearer token',
               },
          });
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const updateOrganization = async (id, payload) => {
     try {
          return await instance.post(`${getUrl()}/organization/admin/organizations/${id}`, payload, {
               headers: {
                    Authorization: `Bearer ${getToken()}` || 'Bearer token',
               },
          });
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const removeOrganization = async ({ id }) => {
     try {
          return await instance.delete(`${getUrl()}/organization/admin/organizations/${id}`, {
               headers: {
                    Authorization: `Bearer ${getToken()}` || 'Bearer token',
               },
          });
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const getOrganization = async (id) => {
     try {
          return await instance.get(`${getUrl()}/organization/admin/organizations/${id}`, {
               headers: {
                    common: {
                         Authorization:
                             `Bearer ${getToken()}` || 'Bearer token',
                    },
               },
          });
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const getOrganizationMembers = async (payload) => {
     try {
          if (payload.query) {
               return await instance.post(
                   `${getUrl()}/organization/admin/${payload.id}/members/search`,
                   { query: payload.query },
                   {
                        headers: {
                             Authorization:
                                 `Bearer ${getToken()}` || 'Bearer token',
                        },
                        params: {
                             per_page: payload.per_page,
                             page: payload.page,
                             sort: payload.sort,
                             direction: payload.direction
                        }
                   }
               );
          }

          return await instance.get(
              `${getUrl()}/organization/admin/${payload.id}/members`,
              {
                   headers: {
                        common: {
                             Authorization:
                                 `Bearer ${getToken()}` || 'Bearer token',
                        },
                   },
                   params: {
                        per_page: payload.per_page,
                        page: payload.page,
                        sort: payload.sort,
                        direction: payload.direction
                   },
              }
          );
     } catch (error) {
          if (error.response) {
               return error.response.data;
          }

          throw Error(error.message);
     }
};

export const updateMemberScope = async (payload) => {
     const { organizationId, userId, scope } = payload;

     try {
          return await instance.post(
               `${getUrl()}/organization/admin/${organizationId}/members/${userId}/scope`,
               { scope },
               {
                    headers: {
                         Authorization:
                              `Bearer ${getToken()}` || 'Bearer token',
                    },
               }
          );
     } catch (error) {
          if (error.response) {
               return error.response.data;
          }

          throw Error(error.message);
     }
};

export const removeMember = async ({ organizationId, userId }) => {
     try {
          return await instance.delete(
              `${getUrl()}/organization/admin/${organizationId}/members/${userId}`,
              {
                   headers: {
                        common: {
                             Authorization:
                                 `Bearer ${getToken()}` || 'Bearer token',
                        },
                   },
              }
          );
     } catch (error) {
          if (error.response) {
               return error.response.data;
          }

          throw Error(error.message);
     }
};

export const addMembers = async (payload) => {
     const { organizationId, users } = payload;

     try {
          return await instance.post(
               `${getUrl()}/organization/admin/${organizationId}/members/add`,
               { users },
               {
                    headers: {
                         Authorization:
                              `Bearer ${getToken()}` || 'Bearer token',
                    },
               }
          );
     } catch (error) {
          if (error.response) {
               return error.response.data;
          }

          throw Error(error.message);
     }
};

export const getAllTiers = async () => {
     try {
          return await instance.get(
               `${getUrl()}/organization/admin/tiers`,
              {
                   headers: {
                        common: {
                             Authorization:
                                 `Bearer ${getToken()}` || 'Bearer token',
                        },
                   }
              }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};
