import React, {useEffect, useRef} from 'react';
import { push } from 'connected-react-router';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Formik } from 'formik';
import { RESET_PASSWORD } from '../../../constants/form.constants';
import { requestResetPassword } from '../../../redux/reducers/authReducer';

import { FormWrapper } from '../auth-styles/AuthPage.style';
import {errorsRefresh} from "../../../redux/reducers/errorsReducer";

const mapStateToProps = (state) => ({
     router: state.router,
     auth: state.authReducer,
     errors: state.errorsReducer.errors
});

const mapDispatchToProps = (dispatch) => ({
     redirectToLogin: () => dispatch(push('/auth/login')),
     requestResetPassword: (values) => dispatch(requestResetPassword(values)),
     refreshErrors: () => dispatch(errorsRefresh())
});

const _ResetPasswordForm = (props) => {
     const { t } = useTranslation();
     const { resetToken } = useParams();

     const {
          auth: {
               resetPasswordRequested,
               resetPasswordSucceeded
               // clearLogin,
          },
          requestResetPassword,
          errors,
          refreshErrors
     } = props;

     const form = useRef();

     const handleResetPasswordSubmit = (values) => {
          requestResetPassword({
               token: resetToken,
               password: values.password,
               password_confirmation: values.password_confirmation,
          });
     };

     useEffect(() => {
          if (resetPasswordSucceeded) {
               form.current.resetForm();
          }
     }, [resetPasswordSucceeded]);

     useEffect(() => {
          if (errors && Object.keys(errors).length > 0) {
               form.current.setSubmitting(false);
               form.current.setErrors(errors);

               refreshErrors();
          }
     }, [errors]);

     return (
          <>
               <Helmet>
                    <title>
                         {t('reset_password.header')} | {t('avovent')}
                    </title>
               </Helmet>
               <Formik
                    validationSchema={RESET_PASSWORD.VALIDATION}
                    onSubmit={handleResetPasswordSubmit}
                    initialValues={RESET_PASSWORD.INITIAL_VALUES}
                    validateOnBlur={false}
                    innerRef={form}
               >
                    {({
                         handleSubmit,
                         handleChange,
                         values,
                         touched,
                         errors,
                    }) => (
                         <FormWrapper>
                              <h1>{t('reset_password.header')}</h1>
                              <div className="resetPasswordForm">
                                   <Form noValidate onSubmit={handleSubmit}>
                                        <Form.Group controlId="password">
                                             <Form.Control
                                                  className="form-row-wrap"
                                                  type="password"
                                                  name="password"
                                                  placeholder={t(
                                                       'reset_password.placeholders.password'
                                                  )}
                                                  value={values.password}
                                                  onChange={handleChange}
                                                  isInvalid={
                                                       touched.password &&
                                                       !!errors.password
                                                  }
                                                  disabled={resetPasswordRequested}
                                             />
                                             <Form.Control.Feedback type="invalid">
                                                  {touched.password &&
                                                       errors.password}
                                             </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group controlId="password_confirmation">
                                             <Form.Control
                                                  className="form-row-wrap"
                                                  type="password"
                                                  name="password_confirmation"
                                                  placeholder={t(
                                                       'reset_password.placeholders.password_confirmation'
                                                  )}
                                                  value={values.password_confirmation}
                                                  onChange={handleChange}
                                                  isInvalid={
                                                       touched.password_confirmation &&
                                                       !!errors.password_confirmation
                                                  }
                                                  disabled={resetPasswordRequested}
                                             />
                                             <Form.Control.Feedback type="invalid">
                                                  {touched.password_confirmation &&
                                                       errors.password_confirmation}
                                             </Form.Control.Feedback>
                                        </Form.Group>
                                        <div className="btn-push-right">
                                             <Button
                                                  type="submit"
                                                  disabled={resetPasswordRequested}
                                             >
                                                  {t('reset_password.button')}
                                             </Button>
                                        </div>
                                   </Form>
                              </div>
                         </FormWrapper>
                    )}
               </Formik>
          </>
     );
};

export const ResetPasswordForm = connect(
     mapStateToProps,
     mapDispatchToProps
)(_ResetPasswordForm);
