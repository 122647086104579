import React, { memo } from 'react';
import SubFooter from './SubFooter';
import FooterWrapper from './Footer.style';

export const Footer = memo(() => {
     return (
          <FooterWrapper>
               <SubFooter />
          </FooterWrapper>
     );
});
