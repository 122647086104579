import * as ACTIONS from '../actions';

export const resetToInitialState = () => ({
     type: ACTIONS.RESET_USER_TO_INITIAL_STATE,
});

export const userClearMessages = () => ({
     type: ACTIONS.USER_CLEAR_MESSAGES,
});

export const requestUserAuthorized = () => ({
     type: ACTIONS.USER_AUTHORIZED_REQUEST,
});

export const successUserAuthorized = (payload) => ({
     type: ACTIONS.USER_AUTHORIZED_SUCCESS,
     payload,
});

export const failUserAuthorized = (error) => ({
     type: ACTIONS.USER_AUTHORIZED_FAILURE,
     error,
});

export const requestUserProfile = () => ({
     type: ACTIONS.USER_PROFILE_REQUEST,
});

export const successUserProfile = (payload) => ({
     type: ACTIONS.USER_PROFILE_SUCCESS,
     payload,
});

export const failUserProfile = (error) => ({
     type: ACTIONS.USER_PROFILE_FAILURE,
     error,
});

export const requestUserAvatarUpload = (payload) => ({
     type: ACTIONS.USER_AVATAR_UPLOAD_REQUEST,
     payload,
});

export const successUserAvatarUpload = (payload) => ({
     type: ACTIONS.USER_AVATAR_UPLOAD_SUCCESS,
     payload,
});

export const failUserAvatarUpload = (error) => ({
     type: ACTIONS.USER_AVATAR_UPLOAD_FAILURE,
     error,
});

export const requestUserAvatarGenerateTemp = (payload) => ({
     type: ACTIONS.USER_AVATAR_GENERATE_TEMP_REQUEST,
     payload,
});

export const successUserAvatarGenerateTemp = (payload) => ({
     type: ACTIONS.USER_AVATAR_GENERATE_TEMP_SUCCESS,
     payload,
});

export const failUserAvatarGenerateTemp = (error) => ({
     type: ACTIONS.USER_AVATAR_GENERATE_TEMP_FAILURE,
     error,
});

export const requestUserProfileUpdate = (payload) => ({
     type: ACTIONS.UPDATE_USER_PROFILE_REQUEST,
     payload,
});

export const successUserProfileUpdate = (payload) => ({
     type: ACTIONS.UPDATE_USER_PROFILE_SUCCESS,
     payload,
});

export const failUserProfileUpdate = (error) => ({
     type: ACTIONS.UPDATE_USER_PROFILE_FAILURE,
     error,
});

export const requestChangeUserPassword = (payload) => ({
     type: ACTIONS.CHANGE_USER_PASSWORD_REQUEST,
     payload,
});

export const successChangeUserPassword = (payload) => ({
     type: ACTIONS.CHANGE_USER_PASSWORD_SUCCESS,
     payload,
});

export const failChangeUserPassword = (error) => ({
     type: ACTIONS.CHANGE_USER_PASSWORD_FAILURE,
     error,
});

export const requestChangeUserEmail = (payload) => ({
     type: ACTIONS.CHANGE_USER_EMAIL_REQUEST,
     payload,
});

export const successChangeUserEmail = (payload) => ({
     type: ACTIONS.CHANGE_USER_EMAIL_SUCCESS,
     payload,
});

export const failChangeUserEmail = (error) => ({
     type: ACTIONS.CHANGE_USER_EMAIL_FAILURE,
     error,
});

export const refreshCurrentUserAvatar = (payload) => ({
     type: ACTIONS.REFRESH_CURRENT_USER_AVATAR,
     payload,
});

export const requestRemoveAvatar = () => ({
     type: ACTIONS.REMOVE_AVATAR_REQUEST,
});

export const successRemoveAvatar = (payload) => ({
     type: ACTIONS.REMOVE_AVATAR_SUCCESS,
     payload,
});

export const failRemoveAvatar = (error) => ({
     type: ACTIONS.REMOVE_AVATAR_FAILURE,
     error,
});

export const requestAvailableLanguages = () => ({
     type: ACTIONS.AVAILABLE_LANGUAGES_REQUEST,
});

export const successAvailableLanguages = (payload) => ({
     type: ACTIONS.AVAILABLE_LANGUAGES_SUCCESS,
     payload,
});

export const failAvailableLanguages = (error) => ({
     type: ACTIONS.AVAILABLE_LANGUAGES_FAILURE,
     error,
});

export const setUsersMeta = (payload) => ({
     type: ACTIONS.ALL_USERS_META,
     payload
});

export const requestAllUsers = (payload) => ({
     type: ACTIONS.ALL_USERS_REQUEST,
     payload
});

export const successAllUsers = (payload) => ({
     type: ACTIONS.ALL_USERS_SUCCESS,
     payload,
});

export const failAllUsers = (error) => ({
     type: ACTIONS.ALL_USERS_FAILURE,
     error,
});

export const requestUsersEverybody = (payload) => ({
     type: ACTIONS.USERS_EVERYBODY_REQUEST,
     payload
});

export const successUsersEverybody = (payload) => ({
     type: ACTIONS.USERS_EVERYBODY_SUCCESS,
     payload,
});

export const failAllUsersEverybody = (error) => ({
     type: ACTIONS.USERS_EVERYBODY_FAILURE,
     error,
});

export const requestNewUser = (payload) => ({
     type: ACTIONS.NEW_USER_REQUEST,
     payload
});

export const successNewUser = (payload) => ({
     type: ACTIONS.NEW_USER_SUCCESS,
     payload,
});

export const failNewUser = (error) => ({
     type: ACTIONS.NEW_USER_FAILURE,
     error,
});

export const requestUpdateUser = (payload) => ({
     type: ACTIONS.UPDATE_USER_REQUEST,
     payload
});

export const successUpdateUser = (payload) => ({
     type: ACTIONS.UPDATE_USER_SUCCESS,
     payload,
});

export const failUpdateUser = (error) => ({
     type: ACTIONS.UPDATE_USER_FAILURE,
     error,
});

export const requestGetUser = (payload) => ({
     type: ACTIONS.GET_USER_REQUEST,
     payload
});

export const successGetUser = (payload) => ({
     type: ACTIONS.GET_USER_SUCCESS,
     payload,
});

export const failGetUser = (error) => ({
     type: ACTIONS.GET_USER_FAILURE,
     error,
});

export const requestRemoveUser = (payload) => ({
     type: ACTIONS.REMOVE_USER_REQUEST,
     payload
});

export const successRemoveUser = (payload) => ({
     type: ACTIONS.REMOVE_USER_SUCCESS,
     payload,
});

export const failRemoveUser = (error) => ({
     type: ACTIONS.REMOVE_USER_FAILURE,
     error,
});

export const INITIAL_STATE = {
     userProfileRequested: false,
     userProfileSucceeded: false,
     userProfileFailed: false,
     userProfileUpdateRequested: false,
     userProfileUpdateSucceeded: false,
     userProfileUpdateFailed: false,
     changeUserPasswordRequested: false,
     changeUserPasswordSucceeded: false,
     changeUserPasswordFailed: false,
     changeUserEmailRequested: false,
     changeUserEmailSucceeded: false,
     changeUserEmailFailed: false,
     userAuthorizedRequested: false,
     userAuthorizedSucceeded: false,
     userAuthorizedFailed: false,
     userAvatarUploadRequested: false,
     userAvatarUploadSucceeded: false,
     userAvatarUploadFailed: false,
     userAvatarGenerateTempRequested: false,
     userAvatarGenerateTempSucceeded: false,
     userAvatarGenerateTempFailed: false,
     refreshCurrentUserAvatar: false,
     removeAvatarRequested: false,
     removeAvatarSucceeded: false,
     removeAvatarFailed: false,
     availableLanguagesRequested: false,
     availableLanguagesSucceeded: false,
     availableLanguagesFailed: false,
     allUsersRequested: false,
     allUsersSucceeded: false,
     allUsersFailed: false,
     usersEverybodyRequested: false,
     usersEverybodySucceeded: false,
     usersEverybodyFailed: false,
     newUserRequested: false,
     newUserSucceeded: false,
     newUserFailed: false,
     updateUserRequested: false,
     updateUserSucceeded: false,
     updateUserFailed: false,
     getUserRequested: false,
     getUserSucceeded: false,
     getUserFailed: false,
     removeUserRequested: false,
     removeUserSucceeded: false,
     removeUserFailed: false,
     authorized: null,
     message: null,
     error: null,
     currentUser: null,
     userGeneratedAvatar: null,
     languages: [],
     users: null,
     usersMeta: null,
     user: null,
     everybody: null
};

export default function userReducer(state = INITIAL_STATE, action) {
     switch (action.type) {
          case ACTIONS.RESET_USER_TO_INITIAL_STATE: {
               return { ...INITIAL_STATE, languages: state.languages };
          }
          case ACTIONS.USER_CLEAR_MESSAGES:
               return {
                    ...state,
                    message: null,
                    error: null,
               };
          case ACTIONS.USER_AUTHORIZED_REQUEST:
               return {
                    ...state,
                    userAuthorizedRequested: true,
                    userAuthorizedSucceeded: false,
                    userAuthorizedFailed: false,
               };
          case ACTIONS.USER_AUTHORIZED_SUCCESS:
               return {
                    ...state,
                    userAuthorizedRequested: false,
                    userAuthorizedSucceeded: true,
                    userAuthorizedFailed: false,
                    authorized: true,
                    error: null,
               };
          case ACTIONS.USER_AUTHORIZED_FAILURE:
               return {
                    ...state,
                    userAuthorizedRequested: false,
                    userAuthorizedSucceeded: false,
                    userAuthorizedFailed: true,
                    error: action.error,
                    authorized: false,
               };
          case ACTIONS.USER_PROFILE_REQUEST:
               return {
                    ...state,
                    userProfileRequested: true,
                    userProfileSucceeded: false,
                    userProfileFailed: false,
               };
          case ACTIONS.USER_PROFILE_SUCCESS:
               return {
                    ...state,
                    userProfileRequested: false,
                    userProfileSucceeded: true,
                    userProfileFailed: false,
                    currentUser: action.payload,
                    error: null,
               };
          case ACTIONS.USER_PROFILE_FAILURE:
               return {
                    ...state,
                    userProfileRequested: false,
                    userProfileSucceeded: false,
                    userProfileFailed: true,
                    error: action.error,
                    currentUser: null,
               };
          case ACTIONS.UPDATE_USER_PROFILE_REQUEST:
               return {
                    ...state,
                    userProfileUpdateRequested: true,
                    userProfileUpdateSucceeded: false,
                    userProfileUpdateFailed: false,
                    message: null,
                    error: null,
               };
          case ACTIONS.UPDATE_USER_PROFILE_SUCCESS:
               return {
                    ...state,
                    userProfileUpdateRequested: false,
                    userProfileUpdateSucceeded: true,
                    refreshCurrentUserAvatar: false,
                    message: action.payload,
                    error: null,
               };
          case ACTIONS.UPDATE_USER_PROFILE_FAILURE:
               return {
                    ...state,
                    userProfileUpdateRequested: false,
                    userProfileUpdateSucceeded: false,
                    userProfileUpdateFailed: true,
                    refreshCurrentUserAvatar: false,
                    message: null,
                    error: action.error,
               };
          case ACTIONS.CHANGE_USER_PASSWORD_REQUEST:
               return {
                    ...state,
                    changeUserPasswordRequested: true,
                    changeUserPasswordSucceeded: false,
                    changeUserPasswordFailed: false,
                    message: null,
                    error: null,
               };
          case ACTIONS.CHANGE_USER_PASSWORD_SUCCESS:
               return {
                    ...state,
                    changeUserPasswordRequested: false,
                    changeUserPasswordSucceeded: true,
                    changeUserPasswordFailed: false,
                    message: action.payload,
                    error: null,
               };
          case ACTIONS.CHANGE_USER_PASSWORD_FAILURE:
               return {
                    ...state,
                    changeUserPasswordRequested: false,
                    changeUserPasswordSucceeded: false,
                    changeUserPasswordFailed: true,
                    message: null,
                    errors: action.error,
               };
          case ACTIONS.CHANGE_USER_EMAIL_REQUEST:
               return {
                    ...state,
                    changeUserEmailRequested: true,
                    changeUserEmailSucceeded: false,
                    changeUserEmailFailed: false,
                    message: null,
                    error: null,
               };
          case ACTIONS.CHANGE_USER_EMAIL_SUCCESS:
               return {
                    ...state,
                    changeUserEmailRequested: false,
                    changeUserEmailSucceeded: true,
                    changeUserEmailFailed: false,
                    message: action.payload,
                    error: null,
               };
          case ACTIONS.CHANGE_USER_EMAIL_FAILURE:
               return {
                    ...state,
                    changeUserEmailRequested: false,
                    changeUserEmailSucceeded: false,
                    changeUserEmailFailed: true,
                    message: null,
                    errors: action.error,
               };
          case ACTIONS.USER_AVATAR_UPLOAD_REQUEST:
               return {
                    ...state,
                    userAvatarUploadRequested: true,
                    userAvatarUploadSucceeded: false,
                    userAvatarUploadFailed: false,
                    message: null,
                    error: null,
               };
          case ACTIONS.USER_AVATAR_UPLOAD_SUCCESS:
               return {
                    ...state,
                    userAvatarUploadRequested: false,
                    userAvatarUploadSucceeded: true,
                    userAvatarUploadFailed: false,
                    message: action.payload,
                    currentUser: {...state.currentUser, is_avatar_default: false},
                    error: null,
               };
          case ACTIONS.USER_AVATAR_UPLOAD_FAILURE:
               return {
                    ...state,
                    userAvatarUploadRequested: false,
                    userAvatarUploadSucceeded: false,
                    userAvatarUploadFailed: true,
                    message: null,
                    error: action.error,
               };
          case ACTIONS.USER_AVATAR_GENERATE_TEMP_REQUEST:
               return {
                    ...state,
                    userAvatarGenerateTempRequested: true,
                    userAvatarGenerateTempSucceeded: false,
                    userAvatarGenerateTempFailed: false,
                    userGeneratedAvatar: null,
                    message: null,
                    error: null,
               };
          case ACTIONS.USER_AVATAR_GENERATE_TEMP_SUCCESS:
               return {
                    ...state,
                    userAvatarGenerateTempRequested: false,
                    userAvatarGenerateTempSucceeded: true,
                    userAvatarGenerateTempFailed: false,
                    userGeneratedAvatar: action.payload,
                    error: null,
               };
          case ACTIONS.USER_AVATAR_GENERATE_TEMP_FAILURE:
               return {
                    ...state,
                    userAvatarGenerateTempRequested: false,
                    userAvatarGenerateTempSucceeded: false,
                    userAvatarGenerateTempFailed: true,
                    message: null,
                    error: action.error,
               };
          case ACTIONS.REFRESH_CURRENT_USER_AVATAR:
               return {
                    ...state,
                    refreshCurrentUserAvatar: true,
                    currentUser: {
                         ...state.currentUser,
                         avatars: action.payload.urls,
                         is_avatar_default:
                             action.payload.is_generated
                                 ? action.payload.is_generated
                                 : state.currentUser.is_avatar_default
                    },
               };
          case ACTIONS.REMOVE_AVATAR_REQUEST:
               return {
                    ...state,
                    removeAvatarRequested: true,
                    removeAvatarSucceeded: false,
                    removeAvatarFailed: false,
               };
          case ACTIONS.REMOVE_AVATAR_SUCCESS:
               return {
                    ...state,
                    removeAvatarRequested: false,
                    removeAvatarSucceeded: true,
                    removeAvatarFailed: false,
                    currentUser: {...state.currentUser, is_avatar_default: true},
                    message: action.payload,
                    error: null,
               };
          case ACTIONS.REMOVE_AVATAR_FAILURE:
               return {
                    ...state,
                    removeAvatarRequested: false,
                    removeAvatarSucceeded: false,
                    removeAvatarFailed: true,
                    error: action.error,
                    message: null,
               };
          case ACTIONS.AVAILABLE_LANGUAGES_REQUEST:
               return {
                    ...state,
                    availableLanguagesRequested: true,
                    availableLanguagesSucceeded: false,
                    availableLanguagesFailed: false,
               };
          case ACTIONS.AVAILABLE_LANGUAGES_SUCCESS:
               return {
                    ...state,
                    availableLanguagesRequested: false,
                    availableLanguagesSucceeded: true,
                    availableLanguagesFailed: false,
                    languages: action.payload,
                    error: null,
               };
          case ACTIONS.AVAILABLE_LANGUAGES_FAILURE:
               return {
                    ...state,
                    availableLanguagesRequested: false,
                    availableLanguagesSucceeded: false,
                    availableLanguagesFailed: true,
                    error: action.error,
                    languages: null,
               };
          case ACTIONS.USERS_EVERYBODY_REQUEST:
               return {
                    ...state,
                    usersEverybodyRequested: true,
                    usersEverybodySucceeded: false,
                    usersEverybodyFailed: false,
               };
          case ACTIONS.USERS_EVERYBODY_SUCCESS:
               return {
                    ...state,
                    usersEverybodyRequested: false,
                    usersEverybodySucceeded: true,
                    usersEverybodyFailed: false,
                    everybody: action.payload,
                    error: null,
               };
          case ACTIONS.USERS_EVERYBODY_FAILURE:
               return {
                    ...state,
                    usersEverybodyRequested: false,
                    usersEverybodySucceeded: false,
                    usersEverybodyFailed: true,
                    error: action.error,
               };
          case ACTIONS.ALL_USERS_REQUEST:
               return {
                    ...state,
                    allUsersRequested: true,
                    allUsersSucceeded: false,
                    allUsersFailed: false,
               };
          case ACTIONS.ALL_USERS_SUCCESS:
               return {
                    ...state,
                    allUsersRequested: false,
                    allUsersSucceeded: true,
                    allUsersFailed: false,
                    users: action.payload,
                    error: null,
               };
          case ACTIONS.ALL_USERS_FAILURE:
               return {
                    ...state,
                    allUsersRequested: false,
                    allUsersSucceeded: false,
                    allUsersFailed: true,
                    error: action.error,
               };
          case ACTIONS.ALL_USERS_META:
               return {
                    ...state,
                    usersMeta: action.payload
               };
          case ACTIONS.NEW_USER_REQUEST:
               return {
                    ...state,
                    newUserRequested: true,
                    newUserSucceeded: false,
                    newUserFailed: false,
               };
          case ACTIONS.NEW_USER_SUCCESS:
               return {
                    ...state,
                    newUserRequested: false,
                    newUserSucceeded: true,
                    newUserFailed: false,
                    error: null,
               };
          case ACTIONS.NEW_USER_FAILURE:
               return {
                    ...state,
                    newUserRequested: false,
                    newUserSucceeded: false,
                    newUserFailed: true,
                    error: action.error,
               };
          case ACTIONS.UPDATE_USER_REQUEST:
               return {
                    ...state,
                    updateUserRequested: true,
                    updateUserSucceeded: false,
                    updateUserFailed: false,
               };
          case ACTIONS.UPDATE_USER_SUCCESS:
               return {
                    ...state,
                    updateUserRequested: false,
                    updateUserSucceeded: true,
                    updateUserFailed: false,
                    error: null,
               };
          case ACTIONS.UPDATE_USER_FAILURE:
               return {
                    ...state,
                    updateUserRequested: false,
                    updateUserSucceeded: false,
                    updateUserFailed: true,
                    error: action.error,
               };
          case ACTIONS.GET_USER_REQUEST:
               return {
                    ...state,
                    getUserRequested: true,
                    getUserSucceeded: false,
                    getUserFailed: false
               };
          case ACTIONS.GET_USER_SUCCESS:
               return {
                    ...state,
                    getUserRequested: false,
                    getUserSucceeded: true,
                    getUserFailed: false,
                    user: action.payload,
                    error: null,
               };
          case ACTIONS.GET_USER_FAILURE:
               return {
                    ...state,
                    getUserRequested: false,
                    getUserSucceeded: false,
                    getUserFailed: true,
                    error: action.error,
               };
          case ACTIONS.REMOVE_USER_REQUEST:
               return {
                    ...state,
                    removeUserRequested: true,
                    removeUserSucceeded: false,
                    removeUserFailed: false
               };
          case ACTIONS.REMOVE_USER_SUCCESS:
               return {
                    ...state,
                    removeUserRequested: false,
                    removeUserSucceeded: true,
                    removeUserFailed: false,
                    message: action.payload,
                    error: null,
               };
          case ACTIONS.REMOVE_USER_FAILURE:
               return {
                    ...state,
                    removeUserRequested: false,
                    removeUserSucceeded: false,
                    removeUserFailed: true,
                    error: action.error,
               };
          default:
               return state;
     }
}
