import React, { useCallback } from "react";
import { useTable, usePagination } from 'react-table';
import {Sorting} from "../../../components/common/sorting/Sorting";
import {inArray} from "../../../utils/array.utils";
import { COLORS } from '../../../constants/style.constants';
import {Pagination, Dropdown} from 'react-bootstrap';
import ChevronDownIcon from '../../../assets/icons/metronic/chevron-down-icon';
import { PAGE_SIZES } from '../../../constants/page.constants';
import i18n from 'i18next';

export const Table = ({ columns, data, pageMeta, sorting, handleSort, handlePageChange, handlePageSizeChange, manualPagination = true, }) => {

    const alignText = useCallback((id) => {
        const config = {
             actions: 'center',
        };

        return config[id] || 'left';
   }, []);

  const transformColumnWidth = useCallback(
      (id) => {
          const config = {
              actions: '100px'
          };

          return config[id] || 'inherit';
      },
      []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
   } = useTable(
        {
             columns,
             data,
             manualPagination,
             pageCount: pageMeta.last_page,
             initialState: {
                  pageSize: pageMeta.per_page,
             },
        },
        usePagination
   );
    
    return (
        <div
                    className="table-container"
                    style={{ marginBottom: '30px' }}
               >
                    <table
                         {...getTableProps()}
                         data={data}
                         className="table"
                         style={{ width: '100%', minWidth: '500px' }}
                    >
                         <thead>
                              {headerGroups.map((headerGroup) => (
                                   <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => {
                                             return (
                                                  <th
                                                       className="th"
                                                       {...column.getHeaderProps()}
                                                       style={{
                                                            padding: '20px 30px',
                                                            verticalAlign: 'middle',
                                                            textAlign: alignText(column.id),
                                                            width: transformColumnWidth(column.id)
                                                       }}
                                                  >
                                                       {column.render('Header')}
                                                      {
                                                          inArray(sorting.tableColumns, column.id) &&
                                                          (<Sorting
                                                              field={sorting.mapColumns[column.id]}
                                                              activeField={sorting.column}
                                                              direction={sorting.direction}
                                                              onSort={handleSort}
                                                          />)
                                                      }
                                                  </th>
                                             );
                                        })}
                                   </tr>
                              ))}
                         </thead>
                         <tbody {...getTableBodyProps()}>
                              {page.slice(0, pageSize).map((row, i) => {
                                   prepareRow(row);
                                   return (
                                        <tr
                                             {...row.getRowProps()}
                                             style={{
                                                  borderTop: `1px solid ${COLORS.PALE_BLUE}`,
                                             }}
                                        >
                                             {row.cells.map((cell) => {
                                                  return (
                                                       <td
                                                            {...cell.getCellProps()}
                                                            className="td"
                                                            style={{
                                                                 width: 'inherit',
                                                                 padding: '20px 30px',
                                                                 verticalAlign: 'middle',
                                                                 textAlign: alignText(cell.column.id),
                                                            }}
                                                       >
                                                            {cell.render(
                                                                 'Cell'
                                                            )}
                                                       </td>
                                                  );
                                             })}
                                        </tr>
                                   );
                              })}
                         </tbody>
                    </table>
                    <div className="datatable__pager">
                         <div className="pagination">
                              <Pagination.First
                                   onClick={() => {
                                        gotoPage(0);
                                        handlePageChange({
                                             page: 1,
                                             pageSize,
                                        });
                                   }}
                                   disabled={!canPreviousPage}
                              />
                              <Pagination.Prev
                                   onClick={() => {
                                        previousPage();
                                        handlePageChange({
                                             page: pageMeta.current_page - 1,
                                             pageSize,
                                        });
                                   }}
                                   disabled={!canPreviousPage}
                              />
                              <ul className="pagination">
                                   {pageOptions.map((option) => (
                                        <li
                                             key={option}
                                             className={`page-item ${
                                                  pageIndex === option
                                                       ? 'active'
                                                       : ''
                                             }`}
                                             style={{ cursor: 'pointer' }}
                                             onClick={() =>
                                                  handlePageChange({
                                                       page: option + 1,
                                                       pageSize,
                                                  })
                                             }
                                        >
                                             <span
                                                  className="page-link"
                                                  onClick={() =>
                                                       gotoPage(option)
                                                  }
                                             >
                                                  <span>{option + 1}</span>
                                             </span>
                                        </li>
                                   ))}
                              </ul>
                              <Pagination.Next
                                   onClick={() => {
                                        nextPage();
                                        handlePageChange({
                                             page: pageMeta.current_page + 1,
                                             pageSize,
                                        });
                                   }}
                                   disabled={!canNextPage}
                              />
                              <Pagination.Last
                                   onClick={() => {
                                        gotoPage(pageCount - 1);
                                        handlePageChange({
                                             page: pageMeta.last_page,
                                             pageSize,
                                        });
                                   }}
                                   disabled={!canNextPage}
                              />
                         </div>
                         <div className="tabel__pager-info">
                              <Dropdown>
                                   <Dropdown.Toggle variant="light">
                                        <span>{pageSize}</span>
                                        <ChevronDownIcon />
                                   </Dropdown.Toggle>
                                   <Dropdown.Menu style={{ minWidth: '0' }}>
                                        {PAGE_SIZES.map((i) => (
                                             <Dropdown.Item
                                                  key={i}
                                                  onClick={() => {
                                                       setPageSize(i);
                                                       handlePageSizeChange(i);
                                                  }}
                                             >
                                                  <span>{i}</span>
                                             </Dropdown.Item>
                                        ))}
                                   </Dropdown.Menu>
                              </Dropdown>
                              <span className="tabel__pager-info--text">
                                   {`${i18n.t(
                                        'showing'
                                   )} ${pageMeta.from} - ${
                                        pageMeta.to
                                   } ${i18n.t('of')} ${
                                        pageMeta.total
                                   }`}
                              </span>
                         </div>
                    </div>
               </div>
    );

};
