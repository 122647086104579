/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Formik } from 'formik';
import {
    requestLogin,
    requestForgottenPassword,
} from '../../../redux/reducers/authReducer';
import { LOGIN } from '../../../constants/form.constants';
import { FormWrapper } from '../auth-styles/AuthPage.style';
import { DefaultSpinner } from '../../common/spinner/DefaultSpinner';

const mapStateToProps = (state) => ({
    auth: state.authReducer
});

const mapDispatchToProps = (dispatch) => ({
    requestLogin: (values) => dispatch(requestLogin(values)),
    requestForgottenPassword: (values) =>
        dispatch(requestForgottenPassword(values)),
});

const _LoginForm = (props) => {
    const {
        auth: { loginRequested },
        requestLogin,
        toggleTransition = null,
        forgotPasswordLink = false,
        registerButton = false
    } = props;

    const { t } = useTranslation();
    const { invitationToken, joinToken } = useParams();

    const handleLoginSubmit = (values) => {

        if (joinToken) {
            values.join_token = joinToken;
        }

        requestLogin(values);
    };

    const initialValues = () => {
        if (invitationToken) {
            return { ...LOGIN.INITIAL_VALUES, invitation: invitationToken };
        }

        if (joinToken) {
            return { ...LOGIN.INITIAL_VALUES, join: joinToken };
        }

        return LOGIN.INITIAL_VALUES;
    };

    return (
        <>
            <Helmet>
                <title>
                    {t('login.header')} | {t('avovent')}
                </title>
            </Helmet>
            <Formik
                validationSchema={LOGIN.VALIDATION}
                onSubmit={handleLoginSubmit}
                initialValues={initialValues()}
                validateOnBlur={false}
                enableReinitialize
            >
                {({
                      handleSubmit,
                      handleChange,
                      values,
                      touched,
                      errors,
                  }) => (
                    <FormWrapper>
                        <h1>{t('login.header')}</h1>
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group controlId="email-login">
                                <Form.Control
                                    className="form-row-wrap"
                                    type="email"
                                    name="email"
                                    placeholder={t(
                                        'login.form.placeholders.email'
                                    )}
                                    value={values.email}
                                    onChange={handleChange}
                                    isInvalid={
                                        touched.email &&
                                        !!errors.email
                                    }
                                    disabled={loginRequested}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {touched.email && errors.email}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="password">
                                <Form.Control
                                    className="form-row-wrap"
                                    type="password"
                                    name="password"
                                    placeholder={t(
                                        'login.form.placeholders.password'
                                    )}
                                    value={values.password}
                                    onChange={handleChange}
                                    isInvalid={
                                        touched.password &&
                                        !!errors.password
                                    }
                                    disabled={loginRequested}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {touched.password &&
                                    errors.password}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <div
                                className="form-actions"
                                style={{ justifyContent: forgotPasswordLink || registerButton ? 'space-between' : 'flex-end' }}
                            >
                                {forgotPasswordLink && (
                                    <a
                                        href="#forgot-password"
                                        onClick={toggleTransition ? toggleTransition : null}
                                    >
                                        {t('login.forgot_password')}
                                    </a>
                                )}

                                {registerButton && (
                                    <Button
                                        type="button"
                                        variant="default"
                                        onClick={toggleTransition ? toggleTransition : null}
                                    >
                                        {t('sign_up.buttons.register_an_account')}
                                    </Button>
                                )}

                                <Button
                                    type="submit"
                                    disabled={loginRequested}
                                >
                                    {t('login.button')}
                                    {<DefaultSpinner isLoading={loginRequested} />}
                                </Button>
                            </div>
                        </Form>
                    </FormWrapper>
                )}
            </Formik>
        </>
    );
};

export const LoginForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(_LoginForm);
