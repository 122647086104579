import React, { useMemo, useEffect, useState, useCallback } from 'react';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import {Button, Alert} from 'react-bootstrap';
import TrashCanIcon from '../../../../assets/icons/metronic/trash-can-icon';
import MoreIcon from '../../../../assets/icons/metronic/more-icon';
import EditIcon from '../../../../assets/icons/metronic/edit-icon';
import { TablePanel } from '../../../common/filterable-table/TablePanel';
import SweetAlert from '../../../common/sweet-alert';
import {
     requestRemoveUser,
} from '../../../../redux/reducers/userReducer';

import FilterableTableWrapper from '../../../common/filterable-table/FilterableTable.style';
import {
     StyledDropdown,
     TableDropdownWrapper,
} from '../../../common/dropdown/Dropdown.style';
import Table from '../../../common/Table';

const ActionsDropdown = ({ item, handleAction }) => {
     const [dropdownShown, setDropdownShown] = useState(false);

     const toggleDropdown = useCallback(() => {
          setDropdownShown(!dropdownShown);
     }, [dropdownShown]);

     return (
          <TableDropdownWrapper>
               <StyledDropdown
                    onToggle={toggleDropdown}
                    show={dropdownShown}
                    alignRight
               >
                    <StyledDropdown.Toggle variant="default">
                         <MoreIcon className="icon" />
                    </StyledDropdown.Toggle>
                    <StyledDropdown.Menu>
                         <StyledDropdown.Item
                              as="div"
                              style={{
                                   margin: 0,
                                   padding: 0,
                              }}
                         >
                              <Link
                                   to={`/users/edit/${item.id}`}
                                   className="dropdown-item"
                              >
                                   <EditIcon />
                                   <span className="edit">
                                        {i18n.t('buttons.edit')}
                                   </span>
                              </Link>
                         </StyledDropdown.Item>
                         <StyledDropdown.Item
                              as="div"
                              style={{
                                   margin: 0,
                                   padding: 0,
                              }}
                         >
                              <Link to="#" className="dropdown-item">
                                   <TrashCanIcon />
                                   <span
                                        className="remove"
                                        onClick={() =>
                                             handleAction({
                                                  userId: item.id,
                                                  type: 'remove',
                                             })
                                        }
                                   >
                                        {i18n.t('buttons.remove')}
                                   </span>
                              </Link>
                         </StyledDropdown.Item>
                    </StyledDropdown.Menu>
               </StyledDropdown>
          </TableDropdownWrapper>
     );
};

const mapStateToProps = (state, ...ownProps) => ({
     ...ownProps,
     router: state.router,
});

const mapDispatchToProps = (dispatch) => ({
     requestRemoveUser: (payload) => dispatch(requestRemoveUser(payload))
});

export const _UsersTable = ({ data, updateCallback, ...props }) => {
     const {
          router: {
               location: { pathname },
          },
          handleSearch,
          pageMeta,
          handlePageSizeChange,
          handlePageChange,
          sorting,
          handleSort,
          isSearching,
          requestRemoveUser
     } = props;

     const { t } = useTranslation();

     const columns = useMemo(
          () => [
               {
                    Header: i18n.t('users.first_name'),
                    accessor: 'firstname',
               },
               {
                    Header: i18n.t('users.last_name'),
                    accessor: 'lastname',
               },
               {
                    Header: i18n.t('users.email'),
                    accessor: 'email',
               },
               {
                    Header: i18n.t('users.roles'),
                    accessor: 'roles',
               },
               {
                    Header: i18n.t('actions'),
                    accessor: 'actions',
               },
          ],
          []
     );

     const [transformedData, setTransformedData] = useState([]);

     const transformData = (data) =>
          data.map((item) => ({
               ...item,
               roles: item.roles.join(', '),
               actions: (
                    <ActionsDropdown
                         item={item}
                         eventId={item.id}
                         handleAction={handleAction}
                         updateCallback={updateCallback}
                    />
               ),
          }));

     useEffect(() => {
          setTransformedData(transformData(data));

          if (!transformedData) return;
     }, [
          data
     ]);

     const handleAction = ({ userId, type }) => {
          const foundUser = data && data.find(({ id }) => id === userId);

          console.error(userId, foundUser);

          if (foundUser && type === 'remove') {
               SweetAlert({
                    title: i18n.t('sweet_alerts.attention'),
                    text: `${i18n.t('sweet_alerts.want_to_remove')} ${foundUser.firstname} ${foundUser.lastname}?`,
                    confirmButtonText: i18n.t('buttons.delete'),
                    cancelButtonText: i18n.t('buttons.cancel'),
                    showCancelButton: true,
                    callback: () => {
                         requestRemoveUser({ id: foundUser.id });
                         const updatedList = data && data.filter(({ id }) => id !== foundUser.id);

                         return (
                              updatedList && setTransformedData(transformData(updatedList))
                         );
                    },
               });
          }
     };

     return (
          <FilterableTableWrapper>
               <TablePanel
                    title={t('users.all_users')}
                    total={pageMeta.total}
                    handleSearch={handleSearch}
                    actions={
                         <Link to={`/users/new`}>
                              <Button variant="success">
                                   {t('users.new_user')}
                              </Button>
                         </Link>
                    }
               />
               
               {transformedData && transformedData.length > 0 && (
                    <Table
                         columns={columns}
                         data={transformedData}
                         pageMeta={pageMeta}
                         sorting={sorting}
                         handleSort={handleSort}
                         handlePageChange={handlePageChange}
                         handlePageSizeChange={handlePageSizeChange}
                    />
               )}
               {isSearching && transformedData.length === 0 && (
                    <div className="events-empty overflow-hidden">
                    <div className="row">
                         <div className="col-sm-12 mb-5">
                              <div className="actions">
                                   <Alert variant={'outline-primary'}>{t('alerts.primary.users_empty')}</Alert>
                              </div>
                         </div>
                    </div>
               </div>
               )} 
               
          </FilterableTableWrapper>
     );
};

export const UsersTable = connect(mapStateToProps, mapDispatchToProps)(_UsersTable);
