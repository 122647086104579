import * as ACTIONS from '../actions';

export const setOrganizationsMeta = (payload) => ({
     type: ACTIONS.ALL_ORGANIZATIONS_META,
     payload
});

export const requestAllOrganizations = (payload) => ({
     type: ACTIONS.ALL_ORGANIZATIONS_REQUEST,
     payload
});

export const successAllOrganizations = (payload) => ({
     type: ACTIONS.ALL_ORGANIZATIONS_SUCCESS,
     payload,
});

export const failAllOrganizations = (error) => ({
     type: ACTIONS.ALL_ORGANIZATIONS_FAILURE,
     error,
});

export const requestAllOrganizationTiers = (payload) => ({
     type: ACTIONS.ALL_ORGANIZATION_TIERS_REQUEST,
     payload
});

export const successAllOrganizationTiers = (payload) => ({
     type: ACTIONS.ALL_ORGANIZATION_TIERS_SUCCESS,
     payload,
});

export const failAllOrganizationTiers = (error) => ({
     type: ACTIONS.ALL_ORGANIZATION_TIERS_FAILURE,
     error,
});

export const requestNewOrganization = (payload) => ({
     type: ACTIONS.NEW_ORGANIZATION_REQUEST,
     payload
});

export const successNewOrganization = (payload) => ({
     type: ACTIONS.NEW_ORGANIZATION_SUCCESS,
     payload,
});

export const failNewOrganization = (error) => ({
     type: ACTIONS.NEW_ORGANIZATION_FAILURE,
     error,
});

export const requestUpdateOrganization = (payload) => ({
     type: ACTIONS.UPDATE_ORGANIZATION_REQUEST,
     payload
});

export const successUpdateOrganization = (payload) => ({
     type: ACTIONS.UPDATE_ORGANIZATION_SUCCESS,
     payload,
});

export const failUpdateOrganization = (error) => ({
     type: ACTIONS.UPDATE_ORGANIZATION_FAILURE,
     error,
});

export const requestRemoveOrganization = (payload) => ({
     type: ACTIONS.REMOVE_ORGANIZATION_REQUEST,
     payload
});

export const successRemoveOrganization = (payload) => ({
     type: ACTIONS.REMOVE_ORGANIZATION_SUCCESS,
     payload,
});

export const failRemoveOrganization = (error) => ({
     type: ACTIONS.REMOVE_ORGANIZATION_FAILURE,
     error,
});

export const requestGetOrganization = (payload) => ({
     type: ACTIONS.GET_ORGANIZATION_REQUEST,
     payload
});

export const successGetOrganization = (payload) => ({
     type: ACTIONS.GET_ORGANIZATION_SUCCESS,
     payload,
});

export const failGetOrganization = (error) => ({
     type: ACTIONS.GET_ORGANIZATION_FAILURE,
     error,
});

export const requestOrganizationMembers = (payload) => ({
     type: ACTIONS.ORGANIZATION_MEMBERS_REQUEST,
     payload,
});

export const successOrganizationMembers = (payload) => ({
     type: ACTIONS.ORGANIZATION_MEMBERS_SUCCESS,
     payload,
});

export const setOrganizationMembersMeta = (payload) => ({
     type: ACTIONS.ORGANIZATION_MEMBERS_META,
     payload
});

export const failOrganizationMembers = (error) => ({
     type: ACTIONS.ORGANIZATION_MEMBERS_FAILURE,
     error,
});

export const requestUpdateMemberScope = (payload) => ({
     type: ACTIONS.UPDATE_MEMBER_SCOPE_REQUEST,
     payload,
});

export const successUpdateMemberScope = (payload) => ({
     type: ACTIONS.UPDATE_MEMBER_SCOPE_SUCCESS,
     payload,
});

export const failUpdateMemberScope = (error) => ({
     type: ACTIONS.UPDATE_MEMBER_SCOPE_FAILURE,
     error,
});

export const requestRemoveMember = (payload) => ({
     type: ACTIONS.REMOVE_MEMBER_REQUEST,
     payload,
});

export const successRemoveMember = (payload) => ({
     type: ACTIONS.REMOVE_MEMBER_SUCCESS,
     payload,
});

export const failRemoveMember = (error) => ({
     type: ACTIONS.REMOVE_MEMBER_FAILURE,
     error,
});

export const requestAddMember = (payload) => ({
     type: ACTIONS.ADD_MEMBER_REQUEST,
     payload,
});

export const successAddMember = (payload) => ({
     type: ACTIONS.ADD_MEMBER_SUCCESS,
     payload,
});

export const failAddMember = (error) => ({
     type: ACTIONS.ADD_MEMBER_FAILURE,
     error,
});

export const INITIAL_STATE = {
     allOrganizationsRequested: false,
     allOrganizationsSucceeded: false,
     allOrganizationsFailed: false,
     newOrganizationRequested: false,
     newOrganizationSucceeded: false,
     newOrganizationFailed: false,
     updateOrganizationRequested: false,
     updateOrganizationSucceeded: false,
     updateOrganizationFailed: false,
     removeOrganizationRequested: false,
     removeOrganizationSucceeded: false,
     removeOrganizationFailed: false,
     getOrganizationRequested: false,
     getOrganizationSucceeded: false,
     getOrganizationFailed: false,
     organizationMembersRequested: false,
     organizationMembersSucceeded: false,
     organizationMembersFailed: false,
     updateMemberScopeRequested: false,
     updateMemberScopeSucceeded: false,
     updateMemberScopeFailed: false,
     removeMemberRequested: false,
     removeMemberSucceeded: false,
     removeMemberFailed: false,
     addMemberRequested: false,
     addMemberSucceeded: false,
     addMemberFailed: false,
     message: null,
     error: null,
     organizations: null,
     organizationsMeta: null,
     membersMeta: null,
     membersList: null,
     organization: null,
     allOrganizationTierRequested: false,
     allOrganizationTierSucceeded: false,
     allOrganizationTierFailed: false,
     organizationTiers: []
};

export default function organizationReducer(state = INITIAL_STATE, action) {
     switch (action.type) {
          case ACTIONS.RESET_ORGANIZATION_TO_INITIAL_STATE: {
               return { ...INITIAL_STATE };
          }
          case ACTIONS.ORGANIZATION_CLEAR_MESSAGES:
               return {
                    ...state,
                    message: null,
                    error: null,
               };
          case ACTIONS.ORGANIZATION_MEMBERS_META:
               return {
                    ...state,
                    membersMeta: action.payload
               };
          case ACTIONS.ALL_ORGANIZATIONS_REQUEST:
               return {
                    ...state,
                    allOrganizationsRequested: true,
                    allOrganizationsSucceeded: false,
                    allOrganizationsFailed: false,
               };
          case ACTIONS.ALL_ORGANIZATIONS_SUCCESS:
               return {
                    ...state,
                    allOrganizationsRequested: false,
                    allOrganizationsSucceeded: true,
                    allOrganizationsFailed: false,
                    organizations: action.payload,
                    error: null,
               };
          case ACTIONS.ALL_ORGANIZATIONS_FAILURE:
               return {
                    ...state,
                    allOrganizationsRequested: false,
                    allOrganizationsSucceeded: false,
                    allOrganizationsFailed: true,
                    error: action.error,
               };
          case ACTIONS.ALL_ORGANIZATION_TIERS_REQUEST:
               return {
                    ...state,
                    allOrganizationTierRequested: true,
                    allOrganizationTierSucceeded: false,
                    allOrganizationTierFailed: false,
               };
          case ACTIONS.ALL_ORGANIZATION_TIERS_SUCCESS:
               return {
                    ...state,
                    allOrganizationTierRequested: false,
                    allOrganizationTierSucceeded: true,
                    allOrganizationTierFailed: false,
                    organizationTiers: action.payload,
                    error: null,
               };
          case ACTIONS.ALL_ORGANIZATION_TIERS_FAILURE:
               return {
                    ...state,
                    allOrganizationTierRequested: false,
                    allOrganizationTierSucceeded: false,
                    allOrganizationTierFailed: true,
                    error: action.error,
               };
          case ACTIONS.ALL_ORGANIZATIONS_META:
               return {
                    ...state,
                    organizationsMeta: action.payload
               };
          case ACTIONS.NEW_ORGANIZATION_REQUEST:
               return {
                    ...state,
                    newOrganizationRequested: true,
                    newOrganizationSucceeded: false,
                    newOrganizationFailed: false,
               };
          case ACTIONS.NEW_ORGANIZATION_SUCCESS:
               return {
                    ...state,
                    newOrganizationRequested: false,
                    newOrganizationSucceeded: true,
                    newOrganizationFailed: false,
                    error: null,
               };
          case ACTIONS.NEW_ORGANIZATION_FAILURE:
               return {
                    ...state,
                    newOrganizationRequested: false,
                    newOrganizationSucceeded: false,
                    newOrganizationFailed: true,
                    error: action.error,
               };
          case ACTIONS.UPDATE_ORGANIZATION_REQUEST:
               return {
                    ...state,
                    updateOrganizationRequested: true,
                    updateOrganizationSucceeded: false,
                    updateOrganizationFailed: false,
               };
          case ACTIONS.UPDATE_ORGANIZATION_SUCCESS:
               return {
                    ...state,
                    updateOrganizationRequested: false,
                    updateOrganizationSucceeded: true,
                    updateOrganizationFailed: false,
                    error: null,
               };
          case ACTIONS.UPDATE_ORGANIZATION_FAILURE:
               return {
                    ...state,
                    updateOrganizationRequested: false,
                    updateOrganizationSucceeded: false,
                    updateOrganizationFailed: true,
                    error: action.error,
               };
          case ACTIONS.REMOVE_ORGANIZATION_REQUEST:
               return {
                    ...state,
                    removeOrganizationRequested: true,
                    removeOrganizationSucceeded: false,
                    removeOrganizationFailed: false,
               };
          case ACTIONS.REMOVE_ORGANIZATION_SUCCESS:
               return {
                    ...state,
                    removeOrganizationRequested: false,
                    removeOrganizationSucceeded: true,
                    removeOrganizationFailed: false,
                    error: null,
               };
          case ACTIONS.REMOVE_ORGANIZATION_FAILURE:
               return {
                    ...state,
                    removeOrganizationRequested: false,
                    removeOrganizationSucceeded: false,
                    removeOrganizationFailed: true,
                    error: action.error,
               };
          case ACTIONS.GET_ORGANIZATION_REQUEST:
               return {
                    ...state,
                    getOrganizationRequested: true,
                    getOrganizationSucceeded: false,
                    getOrganizationFailed: false
               };
          case ACTIONS.GET_ORGANIZATION_SUCCESS:
               return {
                    ...state,
                    getOrganizationRequested: false,
                    getOrganizationSucceeded: true,
                    getOrganizationFailed: false,
                    organization: action.payload,
                    error: null,
               };
          case ACTIONS.GET_ORGANIZATION_FAILURE:
               return {
                    ...state,
                    getOrganizationRequested: false,
                    getOrganizationSucceeded: false,
                    getOrganizationFailed: true,
                    error: action.error,
               };
          case ACTIONS.ORGANIZATION_MEMBERS_REQUEST:
               return {
                    ...state,
                    organizationMembersRequested: true,
                    organizationMembersSucceeded: false,
                    organizationMembersFailed: false,
               };
          case ACTIONS.ORGANIZATION_MEMBERS_SUCCESS:
               return {
                    ...state,
                    organizationMembersRequested: false,
                    organizationMembersSucceeded: true,
                    organizationMembersFailed: false,
                    membersList: action.payload,
                    error: null,
               };
          case ACTIONS.ORGANIZATION_MEMBERS_FAILURE:
               return {
                    ...state,
                    organizationMembersRequested: false,
                    organizationMembersSucceeded: false,
                    organizationMembersFailed: true,
                    error: action.error,
                    membersList: null,
               };
          case ACTIONS.UPDATE_MEMBER_SCOPE_REQUEST:
               return {
                    ...state,
                    updateMemberScopeRequested: true,
                    updateMemberScopeSucceeded: false,
                    updateMemberScopeFailed: false,
                    message: null,
                    error: null,
               };
          case ACTIONS.UPDATE_MEMBER_SCOPE_SUCCESS:
               return {
                    ...state,
                    updateMemberScopeRequested: false,
                    updateMemberScopeSucceeded: true,
                    updateMemberScopeFailed: false,
                    message: '',
                    error: null,
                    membersList: state.membersList.map((member) => {
                         if (member.user_id === action.payload.user_id) {
                              member.scope = action.payload.scope;
                         }

                         return member;
                    }),
               };
          case ACTIONS.UPDATE_MEMBER_SCOPE_FAILURE:
               return {
                    ...state,
                    updateMemberScopeRequested: false,
                    updateMemberScopeSucceeded: false,
                    updateMemberScopeFailed: true,
                    error: action.error,
                    message: null,
               };
          case ACTIONS.REMOVE_MEMBER_REQUEST:
               return {
                    ...state,
                    removeMemberRequested: true,
                    removeMemberSucceeded: false,
                    removeMemberFailed: false,
               };
          case ACTIONS.REMOVE_MEMBER_SUCCESS:
               return {
                    ...state,
                    removeMemberRequested: false,
                    removeMemberSucceeded: true,
                    removeMemberFailed: false,
                    message: action.payload,
                    error: null,
               };
          case ACTIONS.REMOVE_MEMBER_FAILURE:
               return {
                    ...state,
                    removeMemberRequested: false,
                    removeMemberSucceeded: false,
                    removeMemberFailed: true,
                    error: action.error,
                    message: null,
               };
          case ACTIONS.ADD_MEMBER_REQUEST:
               return {
                    ...state,
                    addMemberRequested: true,
                    addMemberSucceeded: false,
                    addMemberFailed: false,
               };
          case ACTIONS.ADD_MEMBER_SUCCESS:
               return {
                    ...state,
                    addMemberRequested: false,
                    addMemberSucceeded: true,
                    addMemberFailed: false,
                    message: action.payload,
                    error: null,
               };
          case ACTIONS.ADD_MEMBER_FAILURE:
               return {
                    ...state,
                    addMemberRequested: false,
                    addMemberSucceeded: false,
                    addMemberFailed: true,
                    error: action.error,
                    message: null,
               };
          default:
               return state;
     }
}
