import React, { useEffect, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { MobileNavigation, Navigation } from '../../../common/navigation';

import Breadcrumb from '../../../common/breadcrumb';
import OrganizationForm from './OrganizationForm';
import {requestUpdateOrganization, requestGetOrganization, requestOrganizationMembers, requestAllOrganizationTiers} from "../../../../redux/reducers/organizationReducer";

import OrganizationsWrapper from '../Organization.style';
import { TeamMembersTable } from './TeamMembersTable';

const mapStateToProps = (state) => ({
     languages: state.userReducer.languages,
     organization: state.organizationReducer.organization,
     membersList: state.organizationReducer.membersList,
     membersMeta: state.organizationReducer.membersMeta,
     organizationTiers: state.organizationReducer.organizationTiers
});
const mapDispatchToProps = (dispatch) => ({
     requestUpdateOrganization: (id, values) => dispatch(requestUpdateOrganization({ id, ...values })),
     requestOrganization: (id) => dispatch(requestGetOrganization(id)),
     requestOrganizationMembers: (payload) => dispatch(requestOrganizationMembers(payload)),
     requestAllOrganizationTiers: () => dispatch(requestAllOrganizationTiers())
});

const _EditOrganization = (props) => {
     const { t } = useTranslation();
     const { id } = useParams();

     const { languages, organization, requestUpdateOrganization, requestOrganization, requestOrganizationMembers, membersList, membersMeta, organizationTiers, requestAllOrganizationTiers } = props;

     const breadcrumbLinks = [
          { to: '/dashboard', text: t('breadcrumb.dashboard') },
          { to: '/organizations', text: t('breadcrumb.organizations') },
          { to: '/organizations/edit/' + id, text: t('breadcrumb.edit_organization') }
     ];

     const handleUpdateOrganization = useCallback((values) => {
          requestUpdateOrganization(id, values);
     }, [id]);

     useEffect(() => {
          requestOrganization(id);
          requestAllOrganizationTiers();
          requestOrganizationMembers({ id, per_page: 50 });
     }, [id]);

     const [queryString, setQueryString] = useState(null);
     const [sorting, setSorting] = useState({
          column: 'id',
          direction: 'desc',
          is_activated: false,
          columns: ['id', 'name', 'email', 'level'],
          tableColumns: ['name', 'email', 'level'],
          mapColumns: {'id': 'id', 'name': 'name', 'email': 'email', 'level': 'level'}
     });

     const handlePageSizeChange = (pageSize) => {
          requestOrganizationMembers({
               id: id,
               per_page: pageSize,
               query: queryString,
               sort: sorting.column,
               direction: sorting.direction
          });
     };

     const handlePageChange = ({ page, pageSize }) =>
          requestOrganizationMembers({
               id: id,
               per_page: pageSize,
               page,
               query: queryString,
               sort: sorting.column,
               direction: sorting.direction
          });

     const handleSort = (field, direction) => {
          setSorting({
               ...sorting,
               is_activated: true,
               column: field,
               direction
          });
     };

     useEffect(() => {
          if (sorting.is_activated) {
               requestOrganizationMembers({
                    id: id,
                    per_page: membersMeta.per_page,
                    page: membersMeta.page,
                    query: queryString,
                    sort: sorting.column,
                    direction: sorting.direction
               });
          }
     }, [sorting]);

     useEffect(() => {
          if (queryString && queryString.length > 0) {
               requestOrganizationMembers({
                    id: id,
                    per_page: membersMeta.per_page,
                    page: 1,
                    query: queryString,
                    sort: sorting.column,
                    direction: sorting.direction
               });
          }
     }, [queryString]);

     const handleSearch = ({ target: { value } }) => {
          setQueryString(value);
          if (value.length === 0) {
               requestOrganizationMembers({
                    id: id,
                    per_page: membersMeta.per_page,
                    page: 1,
                    sort: sorting.column,
                    direction: sorting.direction
               });
          }
     };

     return (
          <OrganizationsWrapper>
               <Helmet>
                    <title>
                         {t('breadcrumb.edit_organization')} | {t('avovent')}
                    </title>
               </Helmet>
               <MobileNavigation />
               <Navigation activated='organizations' loadingBar={!!organization && !!membersList} />
               <Breadcrumb title={t('breadcrumb.edit_organization')} links={breadcrumbLinks} />

               {organization && organization.id === parseInt(id) && (
                    <>
                         <div className="new-user">
                              <div className="container-standard">
                                   <div className="user-content-body card">
                                        <h3 className="card-title">
                                             {t('organizations.edit_organization')} - {organization.name}
                                        </h3>
                                        <section>
                                             <div className="card-body">
                                                  <OrganizationForm
                                                       btnText={t('buttons.save')}
                                                       languages={languages}
                                                       organization={organization}
                                                       handleSave={handleUpdateOrganization}
                                                       tiers={organizationTiers}
                                                  />
                                                  
                                             </div>
                                        </section>
                                   </div>
                              </div>
                         </div>
                         <div className="new-user">
                              <div className="container-standard">
                                   <div className="user-content-body card">
                                        <section>
                                             <div className="card-body">
                                                  <div className="table">
                                                       {membersList && (
                                                            <TeamMembersTable
                                                                 data={membersList}
                                                                 pageMeta={membersMeta}
                                                                 handlePageSizeChange={handlePageSizeChange}
                                                                 handlePageChange={handlePageChange}
                                                                 handleSearch={handleSearch}
                                                                 sorting={sorting}
                                                                 handleSort={handleSort}
                                                                 isSearching={queryString && queryString.length > 0}
                                                                 organization={organization}
                                                            />
                                                       )}
                                                  </div>
                                             </div>
                                        </section>
                                   </div>
                              </div>
                         </div>
                </>
               )}
          </OrganizationsWrapper>
     );
};

export const EditOrganization = connect(mapStateToProps, mapDispatchToProps)(_EditOrganization);
