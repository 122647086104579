import instance, {getToken} from './instance';

export const downloadMediaFile = async (payload) => {
     const { file, name } = payload;

     try {
          const response = await instance.get(file,
              {
                   headers: {
                        Authorization:
                            `Bearer ${getToken()}` || 'Bearer token',
                   },

                   responseType: 'blob',
              }
          );

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', name);
          document.body.appendChild(link);
          link.click();

          return response;
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};