import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Switch, Route, Redirect } from 'react-router-dom';

import Header from '../common/Header';
import Footer from '../common/Footer';
import NotFoundPage from '../common/NotFound';

import UserSettings from './UserSettings';
import Dashboard from './Dashboard';
import { ProtectedRoute } from '../../utils/router.utils';
import { requestUserProfile } from '../../redux/reducers/userReducer';
import {validateSupport} from "../../utils/browser.utils";
import { Users } from './User';
import { Organizations } from './Organization';
import { NewUser } from './User/sub-components/NewUser';
import { EditUser } from './User/sub-components/EditUser';
import { NewOrganization } from './Organization/sub-components/NewOrganization';
import { EditOrganization } from './Organization/sub-components/EditOrganization';

const mapStateToProps = (state) => ({
     router: state.router,
     user: state.userReducer.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
     redirectToDashboard: () => dispatch(push('/dashboard')),
     requestUserProfile: () => dispatch(requestUserProfile()),
});

const _MainPage = memo((props) => {
     const {
          router: {
               location: { pathname },
          },
          user,
          redirectToDashboard,
          requestUserProfile,
     } = props;

     useEffect(() => {
          validateSupport();
     }, [pathname]);

     useEffect(() => {
          if (!user) {
               requestUserProfile();
          }
     }, []);

     useEffect(() => {
          if (pathname === '/') {
               redirectToDashboard();
          }
     }, [pathname, redirectToDashboard]);

     const complete = !!user;

     const renderMain = () => (
          <>
               <Header />
               <div className="content">
                    <Switch>
                         <Route exact path="/" />
                         <ProtectedRoute path="/dashboard">
                              <Dashboard />
                         </ProtectedRoute>
                         <ProtectedRoute path="/user-settings">
                              <UserSettings />
                         </ProtectedRoute>
                         <ProtectedRoute path="/users/new">
                              <NewUser />
                         </ProtectedRoute>
                         <ProtectedRoute path="/users/edit/:id">
                              <EditUser />
                         </ProtectedRoute>
                         <ProtectedRoute path="/users">
                              <Users />
                         </ProtectedRoute>
                         <ProtectedRoute path="/organizations/new">
                              <NewOrganization />
                         </ProtectedRoute>
                         <ProtectedRoute path="/organizations/edit/:id">
                              <EditOrganization />
                         </ProtectedRoute>
                         <ProtectedRoute path="/organizations">
                              <Organizations />
                         </ProtectedRoute>
                         <Route path="*">
                              <Redirect
                                   to={{
                                        pathname: '/not-found-404',
                                        state: {
                                             from: window.location.pathname,
                                             notFound: true,
                                        },
                                   }}
                              >
                                   <NotFoundPage />
                              </Redirect>
                         </Route>
                    </Switch>
               </div>
               <Footer />
          </>
     );

     return complete ? renderMain() : <></>;
});

export const MainPage = connect(mapStateToProps, mapDispatchToProps)(_MainPage);
