import styled from 'styled-components';
import {
     COLORS,
     FONT,
     FORM_TEXT,
     FORM_PLACEHOLDER,
     SIZE,
} from '../../../constants/style.constants';
import { BORDER_THEMED } from '../../../constants/style.constants';

const UsersWrapper = styled.div`
     .info-block {
          margin-top: 5px;
          font-weight: ${FONT.REGULAR};
          font-size: ${SIZE.SMALL};
          line-height: 17px;
          color: ${COLORS.PLACEHOLDER_GREY};
     }

     .body-divider {
          margin: 20px 0 25px;
     }

     .row {
          align-items: start;
          margin-bottom: 20px;

          .col-3 {
               margin-top: 15px;
          }
     }

     .card {
          border-radius: 4px;
          ${BORDER_THEMED};
          margin-bottom: 50px;

          &-title {
               margin: 0;
               padding: 21px 25px;
               font-size: ${SIZE.LARGE};
               font-weight: ${FONT.MEDIUM};
               color: ${COLORS.DARK_BLUE};
               border-bottom: 1px solid ${COLORS.LIGHTER_BLUE};
          }

          .card-body {
               padding: 21px 25px;
          }
     }

     .form-actions {
          display: flex;
          justify-content: space-between;
     }

     .btn-outline-primary {
          border: 1px solid #e1e4ec;
          border-radius: 4px;
          color: ${COLORS.TEXT_GREY};

          &:hover {
               color: ${COLORS.WHITE};
          }
     }

     .form-control {
          font-family: 'IBM Plex Sans', sans-serif;
          font-size: ${SIZE.NORMAL};
          height: calc(1.5em + 1.3rem + 2px);
          padding: 9px 14px;
          background-color: ${COLORS.WHITE};
     }

     form {
          margin: 60px auto;
          ${FORM_TEXT};
          input::placeholder {
               ${FORM_PLACEHOLDER};
               color: ${COLORS.PLACEHOLDER_GREY};
          }

          .col-3 {
               label {
                    margin: 0;
               }
          }

          .btn {
               margin: 0;
          }
     }

     .file_upload {
          align-items: start !important;

          .col-3 {
               margin-top: 11px;
          }
     }

     .table {
          overflow: auto;
     }

     .new-event {
          margin-bottom: 50px;
     }

     .events-empty {
          .content {
               display: flex;
               align-items: start;
               justify-content: space-between;
               min-height: 85vh;
          }
          .actions {
               .btn-success {
                    padding: 13px 44px;
               }
          }
     }

     @media only screen and (max-width: 1320px) {
          form {
               margin: auto;
          }
          .events-empty {
               .content {
                    flex-direction: column;
                    align-items: unset;
               }
          }
     }

     @media only screen and (max-width: 1023px) {
          p,
          .form-control,
          input::placeholder {
               font-size: ${SIZE.TINY} !important;
          }

          .card-title {
               padding: 15px !important;
               font-size: ${SIZE.TINY};
          }

          .card-body {
               padding: 15px !important;

               form {
                    margin: 0 !important;
                    min-width: 100%;
               }
          }
     }
`;

export default UsersWrapper;
