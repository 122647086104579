// Errors
export const ERRORS_REFRESH = 'ERRORS_REFRESH';
export const ERRORS_DISPLAY = 'ERRORS_DISPLAY';

// Auth
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const CLEAR_LOGIN = 'CLEAR_LOGIN';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';
export const FORGOTTEN_PASSWORD_REQUEST = 'FORGOTTEN_PASSWORD_REQUEST';
export const FORGOTTEN_PASSWORD_SUCCESS = 'FORGOTTEN_PASSWORD_SUCCESS';
export const FORGOTTEN_PASSWORD_FAILURE = 'FORGOTTEN_PASSWORD_FAILURE';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
// User
export const RESET_USER_TO_INITIAL_STATE = 'RESET_USER_TO_INITIAL_STATE';
export const USER_CLEAR_MESSAGES = 'USER_CLEAR_MESSAGES';
export const USER_AUTHORIZED_REQUEST = 'USER_AUTHORIZED_REQUEST';
export const USER_AUTHORIZED_SUCCESS = 'USER_AUTHORIZED_SUCCESS';
export const USER_AUTHORIZED_FAILURE = 'USER_AUTHORIZED_FAILURE';
export const USER_PROFILE_REQUEST = 'USER_PROFILE_REQUEST';
export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS';
export const USER_PROFILE_FAILURE = 'USER_PROFILE_FAILURE';
export const USER_AVATAR_REQUEST = 'USER_AVATAR_REQUEST';
export const USER_AVATAR_SUCCESS = 'USER_AVATAR_SUCCESS';
export const USER_AVATAR_FAILURE = 'USER_AVATAR_FAILURE';
export const USER_AVATAR_UPLOAD_REQUEST = 'USER_AVATAR_UPLOAD_REQUEST';
export const USER_AVATAR_UPLOAD_SUCCESS = 'USER_AVATAR_UPLOAD_SUCCESS';
export const USER_AVATAR_UPLOAD_FAILURE = 'USER_AVATAR_UPLOAD_FAILURE';
export const UPDATE_USER_PROFILE_REQUEST = 'UPDATE_USER_PROFILE_REQUEST';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_FAILURE = 'UPDATE_USER_PROFILE_FAILURE';
export const CHANGE_USER_PASSWORD_REQUEST = 'CHANGE_USER_PASSWORD_REQUEST';
export const CHANGE_USER_PASSWORD_SUCCESS = 'CHANGE_USER_PASSWORD_SUCCESS';
export const CHANGE_USER_PASSWORD_FAILURE = 'CHANGE_USER_PASSWORD_FAILURE';
export const CHANGE_USER_EMAIL_REQUEST = 'CHANGE_USER_EMAIL_REQUEST';
export const CHANGE_USER_EMAIL_SUCCESS = 'CHANGE_USER_EMAIL_SUCCESS';
export const CHANGE_USER_EMAIL_FAILURE = 'CHANGE_USER_EMAIL_FAILURE';
export const REFRESH_CURRENT_USER_AVATAR = 'REFRESH_CURRENT_USER_AVATAR';
export const REMOVE_AVATAR_REQUEST = 'REMOVE_AVATAR_REQUEST';
export const REMOVE_AVATAR_SUCCESS = 'REMOVE_AVATAR_SUCCESS';
export const REMOVE_AVATAR_FAILURE = 'REMOVE_AVATAR_FAILURE';
export const AVAILABLE_LANGUAGES_REQUEST = 'AVAILABLE_LANGUAGES_REQUEST';
export const AVAILABLE_LANGUAGES_SUCCESS = 'AVAILABLE_LANGUAGES_SUCCESS';
export const AVAILABLE_LANGUAGES_FAILURE = 'AVAILABLE_LANGUAGES_FAILURE';
export const USER_AVATAR_GENERATE_TEMP_REQUEST = 'USER_AVATAR_GENERATE_TEMP_REQUEST';
export const USER_AVATAR_GENERATE_TEMP_SUCCESS = 'USER_AVATAR_GENERATE_TEMP_SUCCESS';
export const USER_AVATAR_GENERATE_TEMP_FAILURE = 'USER_AVATAR_GENERATE_TEMP_FAILURE';
export const USERS_EVERYBODY_REQUEST = 'USERS_EVERYBODY_REQUEST';
export const USERS_EVERYBODY_SUCCESS = 'USERS_EVERYBODY_SUCCESS';
export const USERS_EVERYBODY_FAILURE = 'USERS_EVERYBODY_FAILURE';
export const ALL_USERS_META = 'ALL_USERS_META';
export const ALL_USERS_REQUEST = 'ALL_USERS_REQUEST';
export const ALL_USERS_SUCCESS = 'ALL_USERS_SUCCESS';
export const ALL_USERS_FAILURE = 'ALL_USERS_FAILURE';
export const NEW_USER_REQUEST = 'NEW_USER_REQUEST';
export const NEW_USER_SUCCESS = 'NEW_USER_SUCCESS';
export const NEW_USER_FAILURE = 'NEW_USER_FAILURE';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';
export const REMOVE_USER_REQUEST = 'REMOVE_USER_REQUEST';
export const REMOVE_USER_SUCCESS = 'REMOVE_USER_SUCCESS';
export const REMOVE_USER_FAILURE = 'REMOVE_USER_FAILURE';
// Organization
export const RESET_ORGANIZATION_TO_INITIAL_STATE = 'RESET_ORGANIZATION_TO_INITIAL_STATE';
export const ORGANIZATION_CLEAR_MESSAGES = 'ORGANIZATION_CLEAR_MESSAGES';
export const ALL_ORGANIZATIONS_META = 'ALL_ORGANIZATIONS_META';
export const ALL_ORGANIZATIONS_REQUEST = 'ALL_ORGANIZATIONS_REQUEST';
export const ALL_ORGANIZATIONS_SUCCESS = 'ALL_ORGANIZATIONS_SUCCESS';
export const ALL_ORGANIZATIONS_FAILURE = 'ALL_ORGANIZATIONS_FAILURE';
export const NEW_ORGANIZATION_REQUEST = 'NEW_ORGANIZATION_REQUEST';
export const NEW_ORGANIZATION_SUCCESS = 'NEW_ORGANIZATION_SUCCESS';
export const NEW_ORGANIZATION_FAILURE = 'NEW_ORGANIZATION_FAILURE';
export const UPDATE_ORGANIZATION_REQUEST = 'UPDATE_ORGANIZATION_REQUEST';
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS';
export const UPDATE_ORGANIZATION_FAILURE = 'UPDATE_ORGANIZATION_FAILURE';
export const REMOVE_ORGANIZATION_REQUEST = 'REMOVE_ORGANIZATION_REQUEST';
export const REMOVE_ORGANIZATION_SUCCESS = 'REMOVE_ORGANIZATION_SUCCESS';
export const REMOVE_ORGANIZATION_FAILURE = 'REMOVE_ORGANIZATION_FAILURE';
export const GET_ORGANIZATION_REQUEST = 'GET_ORGANIZATION_REQUEST';
export const GET_ORGANIZATION_SUCCESS = 'GET_ORGANIZATION_SUCCESS';
export const GET_ORGANIZATION_FAILURE = 'GET_ORGANIZATION_FAILURE';
export const ORGANIZATION_MEMBERS_META = 'ORGANIZATION_MEMBERS_META';
export const ORGANIZATION_MEMBERS_REQUEST = 'ORGANIZATION_MEMBERS_REQUEST';
export const ORGANIZATION_MEMBERS_SUCCESS = 'ORGANIZATION_MEMBERS_SUCCESS';
export const ORGANIZATION_MEMBERS_FAILURE = 'ORGANIZATION_MEMBERS_FAILURE';
export const UPDATE_MEMBER_SCOPE_REQUEST = 'UPDATE_MEMBER_SCOPE_REQUEST';
export const UPDATE_MEMBER_SCOPE_SUCCESS = 'UPDATE_MEMBER_SCOPE_SUCCESS';
export const UPDATE_MEMBER_SCOPE_FAILURE = 'UPDATE_MEMBER_SCOPE_FAILURE';
export const REMOVE_MEMBER_REQUEST = 'REMOVE_MEMBER_REQUEST';
export const REMOVE_MEMBER_SUCCESS = 'REMOVE_MEMBER_SUCCESS';
export const REMOVE_MEMBER_FAILURE = 'REMOVE_MEMBER_FAILURE';
export const ADD_MEMBER_REQUEST = 'ADD_MEMBER_REQUEST';
export const ADD_MEMBER_SUCCESS = 'ADD_MEMBER_SUCCESS';
export const ADD_MEMBER_FAILURE = 'ADD_MEMBER_FAILURE';
export const ALL_ORGANIZATION_TIERS_REQUEST = 'ALL_ORGANIZATION_TIERS_REQUEST';
export const ALL_ORGANIZATION_TIERS_SUCCESS = 'ALL_ORGANIZATION_TIERS_SUCCESS';
export const ALL_ORGANIZATION_TIERS_FAILURE = 'ALL_ORGANIZATION_TIERS_FAILURE';
// Role
export const AVAILABLE_ROLES_REQUEST = 'AVAILABLE_ROLES_REQUEST';
export const AVAILABLE_ROLES_SUCCESS = 'AVAILABLE_ROLES_SUCCESS';
export const AVAILABLE_ROLES_FAILURE = 'AVAILABLE_ROLES_FAILURE';
// Custom
export const DOWNLOAD_MEDIA_FILE_REQUEST = 'DOWNLOAD_MEDIA_FILE_REQUEST';
export const DOWNLOAD_MEDIA_FILE_SUCCESS = 'DOWNLOAD_MEDIA_FILE_SUCCESS';
export const DOWNLOAD_MEDIA_FILE_FAILURE = 'DOWNLOAD_MEDIA_FILE_FAILURE';

// UI
export const SHOW_MAIN_DRAWER = 'SHOW_MAIN_DRAWER';
export const HIDE_MAIN_DRAWER = 'HIDE_MAIN_DRAWER';
export const SHOW_TOOLBAR = 'SHOW_TOOLBAR';
export const HIDE_TOOLBAR = 'HIDE_TOOLBAR';
export const SHOW_DRAWER = 'SHOW_DRAWER';
export const HIDE_DRAWER = 'HIDE_DRAWER';
