import { fork } from 'redux-saga/effects';
import { watchAuth } from './authSaga';
import { watchUser } from './userSaga';
import { watchRole } from './roleSaga';
import { watchOrganization } from './organizationSaga';
import { watchCustom } from './customSaga';

export default function* rootSaga() {
     yield fork(watchAuth);
     yield fork(watchUser);
     yield fork(watchRole);
     yield fork(watchOrganization);
     yield fork(watchCustom);
}
