import * as ACTIONS from '../actions';

export const requestDownloadMediaFile = (payload) => ({
     type: ACTIONS.DOWNLOAD_MEDIA_FILE_REQUEST,
     payload,
});

export const successDownloadMediaFile = (payload) => ({
     type: ACTIONS.DOWNLOAD_MEDIA_FILE_SUCCESS,
     payload,
});

export const failDownloadMediaFile = (error) => ({
     type: ACTIONS.DOWNLOAD_MEDIA_FILE_FAILURE,
     error,
});

export const INITIAL_STATE = {
     downloadMediaFileRequested: false,
     downloadMediaFileSucceeded: false,
     downloadMediaFileFailed: false,
};

export default function customReducer(state = INITIAL_STATE, action) {
     switch (action.type) {
          case ACTIONS.DOWNLOAD_MEDIA_FILE_REQUEST:
               return {
                    ...state,
                    downloadMediaFileRequested: true,
                    downloadMediaFileSucceeded: false,
                    downloadMediaFileFailed: false,
                    exportedData: null,
                    error: null,
               };
          case ACTIONS.DOWNLOAD_MEDIA_FILE_SUCCESS:
               return {
                    ...state,
                    downloadMediaFileRequested: false,
                    downloadMediaFileSucceeded: true,
                    downloadMediaFileFailed: false,
                    exportedData: action.payload,
                    error: null,
               };
          case ACTIONS.DOWNLOAD_MEDIA_FILE_FAILURE:
               return {
                    ...state,
                    downloadMediaFileRequested: false,
                    downloadMediaFileSucceeded: false,
                    downloadMediaFileFailed: true,
                    error: action.error,
                    exportedData: null,
               };
          default:
               return state;
     }
}
