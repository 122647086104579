import React, { memo, lazy, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Breadcrumb from '../../common/breadcrumb';
import {
     requestAllUsers,
} from '../../../redux/reducers/userReducer';
import { UsersTable } from './sub-components/UsersTable';
import { MobileNavigation, Navigation } from '../../common/navigation';

import UsersWrapper from './User.style';

const mapStateToProps = (state) => ({
     router: state.router,
     allUsersRequested: state.userReducer.allUsersRequested,
     usersMeta: state.userReducer.usersMeta,
     usersList: state.userReducer.users,
});

const mapDispatchToProps = (dispatch) => ({
     requestAllUsers: (payload) => dispatch(requestAllUsers(payload)),
});

export const _Users = memo((props) => {
     const {
          router: {
               location: { pathname },
          },
          requestAllUsers,
          usersMeta,
          usersList,
          allUsersRequested
     } = props;

     const [queryString, setQueryString] = useState(null);
     const [sorting, setSorting] = useState({
          column: 'id',
          direction: 'desc',
          is_activated: false,
          columns: ['id', 'firstname', 'lastname', 'email'],
          tableColumns: ['firstname', 'lastname', 'email'],
          mapColumns: {'id': 'id', 'firstname': 'firstname', 'lastname': 'lastname', 'email': 'email'}
     });

     const { t } = useTranslation();

     useEffect(() => {
          requestAllUsers({
               per_page: 50,
          });
     }, []);

     const handlePageSizeChange = (pageSize) => {
          requestAllUsers({
               per_page: pageSize,
               query: queryString,
               sort: sorting.column,
               direction: sorting.direction
          });
     };

     const handlePageChange = ({ page, pageSize }) =>
          requestAllUsers({
               per_page: pageSize,
               page,
               query: queryString,
               sort: sorting.column,
               direction: sorting.direction
          });

     const handleSort = (field, direction) => {
          setSorting({
               ...sorting,
               is_activated: true,
               column: field,
               direction
          });
     };

     useEffect(() => {
          if (sorting.is_activated) {
               requestAllUsers({
                    per_page: usersMeta.per_page,
                    page: usersMeta.page,
                    query: queryString,
                    sort: sorting.column,
                    direction: sorting.direction
               });
          }
     }, [sorting]);

     useEffect(() => {
          if (queryString && queryString.length > 0) {
               requestAllUsers({
                    per_page: usersMeta.per_page,
                    page: 1,
                    query: queryString,
                    sort: sorting.column,
                    direction: sorting.direction
               });
          }
     }, [queryString]);

     const handleSearch = ({ target: { value } }) => {
          setQueryString(value);
          if (value.length === 0) {
               requestAllUsers({
                    per_page: usersMeta.per_page,
                    page: 1,
                    sort: sorting.column,
                    direction: sorting.direction
               });
          }
     };

     const breadcrumbLinks = [
          { to: '/dashboard', text: t('breadcrumb.dashboard') },
          { to: '/users', text: t('breadcrumb.users') }
     ];

     return (
          <UsersWrapper>
               <Helmet>
                    <title>
                         {t('breadcrumb.users')} | {t('avovent')}
                    </title>
               </Helmet>
               
               <MobileNavigation />
               <Navigation activated='users' loadingBar={!!usersList} />
               <Breadcrumb title={t('breadcrumb.users')} links={breadcrumbLinks} />
               
               <div className="container-standard">
                    <div className="table">
                         {usersList && (
                              <UsersTable
                                   data={usersList}
                                   pageMeta={usersMeta}
                                   handlePageSizeChange={handlePageSizeChange}
                                   handlePageChange={handlePageChange}
                                   handleSearch={handleSearch}
                                   sorting={sorting}
                                   handleSort={handleSort}
                                   isSearching={queryString && queryString.length > 0}
                              />
                         )}
                    </div>
               </div>

          </UsersWrapper>
     );
});

export const Users = connect(mapStateToProps, mapDispatchToProps)(_Users);
