import mime from 'mime-types';

export const MIME_PHOTOS = ['image/jpeg', 'image/png'];
export const MIME_SPEAKER_FILES = [
     'application/msword',
     'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
     'application/vnd.ms-powerpoint',
     'application/vnd.openxmlformats-officedocument.presentationml.presentation',
     'application/vnd.ms-excel',
     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
     'application/pdf',
     'application/zip',
];
export const MIME_EMAILS = [
     'application/msword',
     'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
     'application/vnd.ms-powerpoint',
     'application/vnd.openxmlformats-officedocument.presentationml.presentation',
     'application/vnd.ms-excel',
     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
     'application/pdf',
     'image/jpeg',
     'image/png',
];

export const isValidMimeType = (file, mimes) => {
     return mimes.includes(mime.lookup(file.name));
};

export const convertMimesToExtensions = (accept) => {
     const accepted = accept.split(',');

     let extensions = [];
     accepted.forEach((m) => {
          const all = mime.extensions[m.toLowerCase()];

          all.forEach((ext) => {
               extensions.push(ext);
          });
     });

     return extensions.join(', ');
};
