import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { MobileNavigation, Navigation } from '../../../common/navigation';

import Breadcrumb from '../../../common/breadcrumb';
import OrganizationForm from './OrganizationForm';
import {requestNewOrganization, requestAllOrganizationTiers} from "../../../../redux/reducers/organizationReducer";

import OrganizationsWrapper from '../Organization.style';

const mapStateToProps = (state) => ({
     languages: state.userReducer.languages,
     organizationTiers: state.organizationReducer.organizationTiers
});
const mapDispatchToProps = (dispatch) => ({
     requestNewOrganization: (values) => dispatch(requestNewOrganization(values)),
     requestAllOrganizationTiers: () => dispatch(requestAllOrganizationTiers())
});

const _NewOrganization = (props) => {
     const { t } = useTranslation();

     const { languages, requestNewOrganization, requestAllOrganizationTiers, organizationTiers } = props;

     const breadcrumbLinks = [
          { to: '/dashboard', text: t('breadcrumb.dashboard') },
          { to: '/organizations', text: t('breadcrumb.organizations') },
          { to: '/organizations/new', text: t('breadcrumb.organizations_new') }
     ];

     const handleCreateNewOrganization = (values) => {
          requestNewOrganization(values);
     };

     useEffect(() => {
          requestAllOrganizationTiers();
     }, []);

     return (
          <OrganizationsWrapper>
               <Helmet>
                    <title>
                         {t('breadcrumb.organizations_new')} | {t('avovent')}
                    </title>
               </Helmet>
               <MobileNavigation />
               <Navigation activated='organizations' loadingBar={!!languages && !!organizationTiers} />
               <Breadcrumb title={t('breadcrumb.organizations')} links={breadcrumbLinks} />


               <div className="new-user">
                    <div className="container-standard">
                         <div className="user-content-body card">
                              <h3 className="card-title">
                                   {t('organizations.new_organization')}
                              </h3>
                              <section>
                                   <div className="card-body">
                                        <OrganizationForm
                                             btnText={t('buttons.create')}
                                             languages={languages}
                                             handleSave={handleCreateNewOrganization}
                                             tiers={organizationTiers}
                                        />
                                   </div>
                              </section>
                         </div>
                    </div>
               </div>
          </OrganizationsWrapper>
     );
};

export const NewOrganization = connect(mapStateToProps, mapDispatchToProps)(_NewOrganization);
