import React, {useEffect, useRef} from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Button, Row, Col, Form } from 'react-bootstrap';
import { CHANGE_PASSWORD } from '../../../../constants/form.constants';
import {
     requestChangeUserPassword,
     requestUserProfile,
} from '../../../../redux/reducers/userReducer';
import {errorsRefresh} from "../../../../redux/reducers/errorsReducer";
import {Input} from "../../../common/input/Input";
import { DefaultSpinner } from '../../../common/spinner/DefaultSpinner';

const mapStateToProps = (state) => ({
     router: state.router,
     user: state.userReducer,
     errors: state.errorsReducer.errors
});

const mapDispatchToProps = (dispatch) => ({
     requestUserProfile: () => dispatch(requestUserProfile()),
     requestChangeUserPassword: (values) => dispatch(requestChangeUserPassword(values)),
     refreshErrors: () => dispatch(errorsRefresh())
});

const _ChangePasswordForm = (props) => {
     const {
          user: { currentUser, changeUserPasswordRequested, changeUserPasswordSucceeded },
          router: {
               location: { pathname },
          },
          requestUserProfile,
          requestChangeUserPassword,
          errors,
          refreshErrors
     } = props;

     const { t } = useTranslation();
     const form = useRef();

     const handleChangePasswordSubmit = (values) => requestChangeUserPassword(values);

     useEffect(() => {
          if (errors && Object.keys(errors).length > 0) {
               form.current.setSubmitting(false);
               form.current.setErrors(errors);

               refreshErrors();
          }
     }, [errors]);

     useEffect(() => {
          if (!currentUser) {
               requestUserProfile();
          }
     }, [pathname, requestUserProfile]);

     useEffect(() => {
          if (changeUserPasswordSucceeded) {
               form.current.resetForm();
          }
     }, [changeUserPasswordSucceeded]);

     return (
          <Formik
               innerRef={form}
               validationSchema={CHANGE_PASSWORD.VALIDATION}
               onSubmit={handleChangePasswordSubmit}
               initialValues={CHANGE_PASSWORD.INITIAL_VALUES}
               validateOnBlur={false}
               enableReinitialize={true}
          >
               {({ handleSubmit, handleChange, values, touched, errors }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                         <Form.Group as={Row} controlId="current">
                              <Col sm={3}>
                                   <Form.Label>
                                        {t(
                                             'user_settings.change_password_form.labels.current_password'
                                        )}
                                   </Form.Label>
                              </Col>
                              <Col sm={9}>
                                   <Input
                                        type="password"
                                        field="current"
                                        values={values}
                                        placeholder={t(
                                             'user_settings.change_password_form.placeholders.current_password'
                                        )}
                                        handleChange={handleChange}
                                        touched={touched}
                                        errors={errors}
                                        disabled={changeUserPasswordRequested}
                                   />
                              </Col>
                         </Form.Group>
                         <Form.Group as={Row} controlId="password">
                              <Col sm={3}>
                                   <Form.Label>
                                        {t(
                                             'user_settings.change_password_form.labels.new_password'
                                        )}
                                   </Form.Label>
                              </Col>
                              <Col sm={9}>
                                   <Input
                                        type="password"
                                        field="password"
                                        values={values}
                                        placeholder={t(
                                             'user_settings.change_password_form.placeholders.new_password'
                                        )}
                                        handleChange={handleChange}
                                        touched={touched}
                                        errors={errors}
                                        disabled={changeUserPasswordRequested}
                                   />
                              </Col>
                         </Form.Group>
                         <Form.Group as={Row} controlId="passwordConfirmation">
                              <Col sm={3}>
                                   <Form.Label>
                                        {t(
                                             'user_settings.change_password_form.labels.repeat_password'
                                        )}
                                   </Form.Label>
                              </Col>
                              <Col sm={9}>
                                   <Input
                                        type="password"
                                        field="password_confirmation"
                                        values={values}
                                        placeholder={t(
                                             'user_settings.change_password_form.placeholders.repeat_password'
                                        )}
                                        handleChange={handleChange}
                                        touched={touched}
                                        errors={errors}
                                        disabled={changeUserPasswordRequested}
                                   />
                              </Col>
                         </Form.Group>
                         <hr className="body-divider" />
                         <div className="form-actions">
                              <Button
                                   variant="success"
                                   size="sm"
                                   type="submit"
                                   disabled={changeUserPasswordRequested}
                              >
                                   {t(
                                        'user_settings.change_password_form.button'
                                   )}
                                   {<DefaultSpinner isLoading={changeUserPasswordRequested} />}
                              </Button>
                         </div>
                    </Form>
               )}
          </Formik>
     );
};

export const ChangePasswordForm = connect(
     mapStateToProps,
     mapDispatchToProps
)(_ChangePasswordForm);
