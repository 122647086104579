import React, {useState, useEffect, useRef} from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Button, Row, Col, Form } from 'react-bootstrap';
import { SETTINGS } from '../../../../constants/form.constants';
import {
     requestUserAvatarUpload,
     requestUserProfileUpdate,
     requestRemoveAvatar,
     requestUserAvatarGenerateTemp
} from '../../../../redux/reducers/userReducer';
import PictureUploader from '../../../common/picture-uploader';

import Input from '../../../common/input';
import {dataURLtoFile} from "../../../../utils/data.utils";
import {errorsRefresh} from "../../../../redux/reducers/errorsReducer";
import {MIME_PHOTOS} from "../../../../utils/mime.utils";
import {AVATAR_FILE_LIMIT} from "../../../../utils/file.utils";
import { DefaultSpinner } from '../../../common/spinner/DefaultSpinner';

const mapStateToProps = (state) => ({
     user: state.userReducer,
     userProfileUpdateRequested: state.userReducer.userProfileUpdateRequested,
     uploadStarted: state.userReducer.userAvatarUploadRequested,
     uploadSucceeded: state.userReducer.userAvatarUploadSucceeded,
     removeAvatarStarted: state.userReducer.removeAvatarRequested,
     removeAvatarSucceeded: state.userReducer.removeAvatarSucceeded,
     userGeneratedAvatar: state.userReducer.userGeneratedAvatar,
     userGeneratedAvatarSucceeded: state.userReducer.userAvatarGenerateTempSucceeded,
     errors: state.errorsReducer.errors
});

const mapDispatchToProps = (dispatch) => ({
     requestUserAvatarUpload: (values) => dispatch(requestUserAvatarUpload(values)),
     requestUserProfileUpdate: (values) => dispatch(requestUserProfileUpdate(values)),
     requestRemoveAvatar: () => dispatch(requestRemoveAvatar()),
     requestUserAvatarGenerate: (values) => dispatch(requestUserAvatarGenerateTemp(values)),
     refreshErrors: () => dispatch(errorsRefresh())
});

const _SettingsForm = (props) => {
     const {
          user: { currentUser },
          requestUserAvatarUpload,
          requestUserProfileUpdate,
          uploadSucceeded,
          removeAvatarSucceeded,
          userGeneratedAvatar,
          requestUserAvatarGenerate,
          userGeneratedAvatarSucceeded,
          userProfileUpdateRequested,
          errors,
          refreshErrors
     } = props;

     const { t } = useTranslation();
     const form = useRef();

     const [avatar, setAvatar] = useState(null);
     const [fullPreview, setFullPreview] = useState(null);
     const [defaultAvatar, setDefaultAvatar] = useState(null);
     const [previewAvatar, setPreviewAvatar] = useState(null);
     const [fileForUpload, setFileForUpload] = useState(null);
     const [isLoading, setIsLoading] = useState(false);

     useEffect(() => {
          if (currentUser.avatars) {
               setAvatar(currentUser.avatars['240x240']);
               setFullPreview(currentUser.is_avatar_default ? null : currentUser.avatars);
               setDefaultAvatar(currentUser.avatars['240x240']);
          }
     }, [currentUser]);

     useEffect(() => {
          if (errors && Object.keys(errors).length > 0) {
               form.current.setSubmitting(false);
               form.current.setErrors(errors);

               refreshErrors();
          }
     }, [errors]);

     useEffect(() => {
          if (uploadSucceeded) {
               setAvatar(currentUser.avatars['240x240']);
               setFullPreview(currentUser.is_avatar_default ? null : currentUser.avatars);
               setDefaultAvatar(currentUser.avatars['240x240']);
               setIsLoading(false);
          }
     }, [uploadSucceeded]);

     useEffect(() => {
          if (removeAvatarSucceeded) {
               setAvatar(currentUser.avatars['240x240']);
               setFullPreview(currentUser.is_avatar_default ? null : currentUser.avatars);
               setDefaultAvatar(currentUser.avatars['240x240']);
               setIsLoading(false);
          }
     }, [removeAvatarSucceeded]);

     useEffect(() => {
          if (userGeneratedAvatarSucceeded && userGeneratedAvatar) {
               setAvatar(userGeneratedAvatar);
               setDefaultAvatar(userGeneratedAvatar);
               setFullPreview(null);
               setIsLoading(false);

               const file = dataURLtoFile(userGeneratedAvatar, 'generated.png');

               setFileForUpload({
                    file,
                    crop: {
                         width: 1000,
                         height: 1000,
                         x: 0,
                         y: 0,
                         is_generated: true
                    }
               });
          }
     }, [userGeneratedAvatarSucceeded]);

     const getFile = (file, crop) => {
          setPreviewAvatar(crop.canvas);
          setFileForUpload({
               file,
               crop: {
                    width: crop.width,
                    height: crop.height,
                    x: crop.x,
                    y: crop.y
               }
          });
     };

     const getDefault = () => defaultAvatar;

     const handleSettingsFormSubmit = (values) => {
          if (fileForUpload) {
               requestUserAvatarUpload({avatar: fileForUpload.file, crop: fileForUpload.crop});
               setIsLoading(true);
               setFileForUpload(null);
               setPreviewAvatar(null);
          }

          requestUserProfileUpdate({
               firstname: values.firstname,
               lastname: values.lastname,
               email: values.email,
          });
     };

     const removeUserAvatar = () => {
          setIsLoading(true);
          setAvatar(null);
          setFullPreview(null);
          setDefaultAvatar(null);

          requestUserAvatarGenerate({name: currentUser.firstname + ' ' + currentUser.lastname});
     };

     const removeAvatarPreview = () => {
          setPreviewAvatar(null);
          setFileForUpload(null);
     };

     return (
          <Formik
               validationSchema={SETTINGS.VALIDATION}
               onSubmit={handleSettingsFormSubmit}
               initialValues={{
                    firstname: currentUser.firstname,
                    lastname: currentUser.lastname,
                    email: currentUser.email,
               }}
               validateOnBlur={false}
               innerRef={form}
          >
               {({ handleSubmit, handleChange, values, touched, errors }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                         <Form.Group as={Row} controlId="firstname">
                              <Col sm={3}>
                                   <Form.Label>
                                        {t(
                                             'user_settings.settings_form.first_name'
                                        )}
                                   </Form.Label>
                              </Col>
                              <Col sm={9}>
                                   <Input
                                        type="text"
                                        field="firstname"
                                        values={values}
                                        placeholder={currentUser.firstname}
                                        handleChange={handleChange}
                                        touched={touched}
                                        errors={errors}
                                   />
                              </Col>
                         </Form.Group>
                         <Form.Group as={Row} controlId="lastname">
                              <Col sm={3}>
                                   <Form.Label>
                                        {t(
                                             'user_settings.settings_form.last_name'
                                        )}
                                   </Form.Label>
                              </Col>
                              <Col sm={9}>
                                   <Input
                                        type="text"
                                        field="lastname"
                                        values={values}
                                        placeholder={currentUser.lastname}
                                        handleChange={handleChange}
                                        touched={touched}
                                        errors={errors}
                                   />
                              </Col>
                         </Form.Group>
                         <section className="avatar-section">
                              <Form.Group
                                   as={Row}
                                   controlId="photo"
                                   className="file_upload"
                              >
                                   <Col sm={3}>
                                        <Form.Label>
                                             {t(
                                                  'user_settings.settings_form.profile_photo'
                                             )}
                                        </Form.Label>
                                   </Col>
                                   <Col sm={9}>
                                        <PictureUploader
                                             picture={avatar}
                                             preview={previewAvatar}
                                             getFile={getFile}
                                             getDefault={getDefault}
                                             isLoading={isLoading}
                                             isDefault={currentUser.is_avatar_default}
                                             handleRemovePicture={removeUserAvatar}
                                             handleRemovePreview={removeAvatarPreview}
                                             accept={MIME_PHOTOS.join(',')}
                                             fileLimit={AVATAR_FILE_LIMIT}
                                             fullPreview={fullPreview}
                                        />
                                   </Col>
                              </Form.Group>
                         </section>
                         <hr className="body-divider" />
                         <div className="form-actions">
                              <Button variant="success" size="sm" type="submit" disabled={userProfileUpdateRequested}>
                                   {t('user_settings.settings_form.button')}
                                   {<DefaultSpinner isLoading={userProfileUpdateRequested} />}
                              </Button>
                         </div>
                    </Form>
               )}
          </Formik>
     );
};

export const SettingsForm = connect(
     mapStateToProps,
     mapDispatchToProps
)(_SettingsForm);
