import React, { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { MobileNavigation, Navigation } from '../../../common/navigation';

import Breadcrumb from '../../../common/breadcrumb';
import UserForm from './UserForm';
import {requestAvailableRoles} from "../../../../redux/reducers/roleReducer";
import {requestUpdateUser, requestGetUser} from "../../../../redux/reducers/userReducer";

import UsersWrapper from '../User.style';

const mapStateToProps = (state) => ({
     roles: state.roleReducer.roles,
     user: state.userReducer.user,
});
const mapDispatchToProps = (dispatch) => ({
     requestAvailableRoles: () => dispatch(requestAvailableRoles()),
     requestUpdateUser: (id, values) => dispatch(requestUpdateUser({ id, ...values })),
     requestUser: (id) => dispatch(requestGetUser(id))
});

const _EditUser = (props) => {
     const { t } = useTranslation();
     const { id } = useParams();

     const { roles, user, requestAvailableRoles, requestUpdateUser, requestUser } = props;

     const breadcrumbLinks = [
          { to: '/dashboard', text: t('breadcrumb.dashboard') },
          { to: '/users', text: t('breadcrumb.users') },
          { to: '/users/edit/' + id, text: t('breadcrumb.users_edit') }
     ];

     const handleUpdateUser = useCallback((values) => {
          requestUpdateUser(id, values);
     }, [id]);

     useEffect(() => {
          requestAvailableRoles();
          requestUser(id);
     }, [id]);

     return (
          <UsersWrapper>
               <Helmet>
                    <title>
                         {t('breadcrumb.users_edit')} | {t('avovent')}
                    </title>
               </Helmet>
               <MobileNavigation />
               <Navigation activated='users' loadingBar={!!roles && !!user} />
               <Breadcrumb title={t('breadcrumb.users_edit')} links={breadcrumbLinks} />

               {user && user.id === parseInt(id) && (
                    <div className="new-user">
                    <div className="container-standard">
                         <div className="user-content-body card">
                              <h3 className="card-title">
                                   {t('users.edit_user')} - {user.firstname} {user.lastname}
                              </h3>
                              <section>
                                   <div className="card-body">
                                        <UserForm
                                             btnText={t('buttons.save')}
                                             roles={roles}
                                             user={user}
                                             isEditing={true}
                                             handleSave={handleUpdateUser}
                                        />
                                        
                                   </div>
                              </section>
                         </div>
                    </div>
               </div>
               )}
          </UsersWrapper>
     );
};

export const EditUser = connect(mapStateToProps, mapDispatchToProps)(_EditUser);
